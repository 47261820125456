import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Account} from '../_classes/account';
import {HttpClient} from '@angular/common/http';
import {BASE_URL} from '../globals';
import {UrlService} from './url.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  baseUrl = BASE_URL;

  constructor(
    private http: HttpClient,
    private urlService: UrlService
  ) {
    this.baseUrl = this.urlService.getURL();
  }

  getAccount(): Observable<Account> {
    return this.http.get<Account>(`${this.baseUrl}api/user-requests/account/`)
      .pipe(
        tap(_ => console.log('fetched account data'))
      );
  }
  updateAccount(data: any): Observable<any> {
    return this.http.put(`${this.baseUrl}api/user-requests/account-details/`, data).pipe(
      tap(_ => console.log('Person updated')));
  }
  updateEmail(e: string): Observable<any> {
    return this.http.put(`${this.baseUrl}change-email/`, {email: e}).pipe(
      tap(_ => console.log('Email changed to ' + e)));
  }
  updatePassword(password: string, oldPassword: string): Observable<any> {
    return this.http.post(`${this.baseUrl}rest-auth/password/change/`, {new_password1: password, new_password2: password, old_password: oldPassword}).pipe(
      tap(() => console.log('password updated')),
      catchError(this.handleError<any>('updatePassword'))
    );
  }
  getUnconfirmedTrainings(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/user-requests/not-confirmed-trainings/`)
      .pipe(
        tap(_ => console.log('fetched unconfirmed trainings'))
      );
  }
  confirmTraining(playerId: number, trainingId: number): Observable<any> {
    return this.http.put(`${this.baseUrl}api/user-requests/confirm-training/`, {player: playerId, training: trainingId}).pipe(
      tap(() => console.log('training confirmed')),
      catchError(this.handleError<any>('confirmTraining'))
    );
  }

  resetPassword(e: string): Observable<any> {
    return this.http.post(`${this.baseUrl}api/password_reset/`, {email: e}).pipe(
      tap(() => console.log('reset password')),
      catchError(this.handleError<any>('resetPassword'))
    );
  }

  resetPasswordWithToken(password, token): Observable<any> {
    return this.http.post(`${this.baseUrl}api/password_reset/confirm/`, {password, token});
  }

  uploadImg(data: any): Observable<any> {
    return this.http.put(`${this.baseUrl}api/user-requests/upload-photo/`, {data}).pipe(
      tap(_ => console.log('uploaded img')));
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result
      return of(result as T);
    };
  }
}
