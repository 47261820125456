import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SidenavRoutingModule } from './sidenav-routing.module';
import { AngularMaterialModule } from '../-angular-material/-angular-material.module';
import { SidenavComponent } from './sidenav.component';


@NgModule({
    declarations: [SidenavComponent],
    exports: [
        SidenavComponent
    ],
    imports: [
        CommonModule,
        SidenavRoutingModule,
        AngularMaterialModule
    ]
})
export class SidenavModule { }
