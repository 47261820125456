<h1> Allgemeine Geschäftsbedingungen Stand 09/2020</h1>
<ol>
  <li>
    <strong>Einbeziehung der AGB</strong><br />
    Die nachstehenden Bedingungen gelten für alle im Zusammenhang mit dem Betrieb der BeTennis GmbH geschlossenen Verträge. Nebenabreden oder Ergänzungen sind nur gültig, wenn sie durch uns schriftlich bestätigt werden.
  </li>
  <li>
    <strong>Vertragsabschluss und Vertragsdauer</strong><br />
    Die Abgabe Ihrer Anmeldung stellt ein Angebot an uns zum Abschluss eines Dienstleistungsvertrages dar. Die Tennisschule ist in der Annahme Ihres Angebotes frei. Der Vertrag kommt erst zu Stande, wenn die Tennisschule Ihr Angebot durch Mitteilung eines konkreten Termins zur Durchführung des Trainings annimmt.
    Der Vertrag besitzt Gültigkeit für den jeweils ausgeschriebenen Trainingszeitraum und kann nicht vorzeitig gekündigt werden. Bei vorzeitiger Kündigung ist der volle Rechnungsbetrag zu entrichten. Eine Rückerstattung bereits gezahlter Beträge findet nicht statt. Die allgemeinen Geschäftsbedingungen, Platz- und Hallenordnung der jeweiligen Tennisvereine und kommerziellen Anlagen, auf denen das Tennistraining durchgeführt wird, sind für alle Trainingsteilnehmer verbindlich.<br />
  </li>
  <li>
    <strong>Training</strong><br />
    Unser Leistungsangebot umfasst Mannschafts-, Gruppen und Einzeltraining. Die BeTennis GmbH teilt die Gruppen nach praktischen Notwendigkeiten, insbesondere Spielstärke und Alter ein. Bei Bedarf kann die Einteilung geändert werden. Auf die Wünsche unserer Kunden werden wir nach Möglichkeit Rücksicht nehmen.
  </li>
  <li>
    <strong>Durchführung des Trainings</strong><br />
    Nach schriftlicher Anmeldung erfolgt im beiderseitigen Einvernehmen eine Terminabsprache. Der Schüler ist verpflichtet, sich rechtzeitig vor Beginn des jeweiligen Trainingsabschnittes nach seinen Trainingszeiten zu erkundigen.<br />
    Die Einteilung des Trainers bleibt der BeTennis GmbH vorbehalten.<br />
    Bei nicht voll belegten Kursen kann es zu Gruppenveränderungen kommen, die eine erneute Absprache erforderlich machen. Eine solche Änderung stellt keinen Kündigungsgrund dar.<br />
    Falls dies aus organisatorischen Gründen notwendig ist, ist es der BeTennis GmbH gestattet auch während der Saison einen Trainerwechsel vorzunehmen bzw. Vertretungsunterricht zu erteilen.<br />
    Bei schlechten Wetterverhältnissen wird die Trainingseinheit in Absprache mit dem Kunden abgesagt. Trainingseinheiten, die wegen Schlechtwetter entfallen werden nicht nachgeholt. Ein gebuchtes Gruppentraining ist nicht übertragbar.<br />
    Mögliche Erkrankungen oder andere gesundheitliche Probleme müssen dem Trainer vor Beginn der Trainerstunde ausdrücklich mitgeteilt werden.
  </li>
  <li>
    <strong>Trainingskosten</strong><br />
    Die Kursgebühren sind für den jeweiligen Trainingszeitraum nach Rechnungsstellung unverzüglich zu bezahlen. Der Gesamtbetrag bezieht sich immer auf den angegebenen Trainingszeitraum.
  </li>
  <li>
    <strong>Ausgefallene Stunden</strong><br />
    Einzeltraining<br />
    Training, das der Trainingsteilnehmer – gleich aus welchem Grund – nicht in Anspruch nimmt wird nicht nachgeholt und wird in vollem Umfang vom Kunden bezahlt. Training, das wegen schlechten Wetters, behördlicher Anordnung (z.B. lock-down) oder höherer Gewalt ausfällt, wird nicht nachgeholt. Gemäß §615 BGB entfällt unsere Leistungsverpflichtung. Der Anspruch auf das Trainingsentgelt, einschließlich der in der Wintersaison anfallenden anteiligen Hallenmiete, bleibt bestehen.<br />
    Gruppentraining<br />
    Im Rahmen des Gruppentrainings versäumte Stunden können aus organisatorischen Gründen vom Kursteilnehmer nicht nachgeholt werden. Training, das wegen schlechten Wetters, behördlicher Anordnung (z.B. lock-down) oder höherer Gewalt ausfällt, wird nicht nachgeholt. Gemäß §615 BGB entfällt unsere Leistungsverpflichtung. Der Anspruch auf das Trainingsentgelt, einschließlich der in der Wintersaison anfallenden anteiligen Hallenmiete, bleibt bestehen.<br />
    Von der Tennisschule abgesagte Stunden sowohl des Einzel- als auch des Gruppentrainings werden nachgeholt. Falls dies binnen 6 Monaten nicht möglich ist, werden die Kosten zurückerstattet.
  </li>
  <li>
    <strong>Aufsicht bei Minderjährigen</strong><br />
    Unsere Aufsichtspflicht für minderjährige Kinder beschränkt sich auf die Dauer des Trainings.<br />
    Die Eltern/Erziehungsberechtigten haben dafür Sorge zu tragen, dass die Aufsicht für Ihr(e) Kind(er) vor und nach dem Trainingsbetrieb nahtlos gewährleistet ist. Von Seiten der Tennisschule wird außerhalb des Trainings keine Haftung übernommen. Die Eltern/Erziehungsberechtigten informieren Ihre Kinder, dass sie den Trainingsbereich nicht verlassen dürfen und den Anweisungen der Trainier Folge zu leisten haben. Die Tennisschule übernimmt keine Haftung, wenn ein Kind den Trainingsbereich verlässt.
  </li>
  <li>
    <strong>Ausschluss vom Training</strong><br />
    Wir behalten uns vor, Trainingsteilnehmer aus einer Gruppe auszuschließen, wenn diese trotz Ermahnung den Anweisungen des Trainers keine Folge leisten oder das Training stören. In einem solchem Fall muss der/die Minderjährige bis zur Abholung durch die Eltern/Erziehungsberechtigten im Trainingsbereich bleiben. Der/die Ausgeschlossene bzw. deren Eltern/Erziehungsberechtigten haben keinen Anspruch auf Erstattung des (anteiligen) Trainingsentgelts.
  </li>
  <li>
    <strong>Haftung</strong><br />
    Unsere Haftung für Schäden im Zusammenhang mit dem Training beschränkt sich auf Vorsatz und grobe Fahrlässigkeit. 10.Datenschutz<br />
    Ihre persönlichen Daten werden bei uns elektronisch gespeichert. Eine Weitergabe Ihrer Daten an Dritte erfolgt nicht. Nach Beendigung des Trainings sind wir befugt, Ihre Daten für die Dauer von 3 Jahren aufzubewahren.
  </li>
  <li>
    <strong>Erfüllungsort und Gerichtsstand</strong><br />
    Erfüllungsort und Gerichtsstand ist Regensburg.
  </li>
</ol>
<p>
  Ihre BeTennis GmbH
</p>
<p>
  Be Tennis GmbH<br />
  An der Schloßbreite 11a<br />
  93080 Pentling
</p>
