import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedImageCropperRoutingModule } from './shared-image-cropper-routing.module';
import { SharedImageCropperComponent } from './shared-image-cropper.component';
import {ReactiveFormsModule} from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import {FlexModule} from '@angular/flex-layout';
import { AngularMaterialModule } from '../-angular-material/-angular-material.module';


@NgModule({
  declarations: [SharedImageCropperComponent],
  exports: [
    SharedImageCropperComponent
  ],
  imports: [
    CommonModule,
    SharedImageCropperRoutingModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    ImageCropperModule,
    FlexModule
  ]
})
export class SharedImageCropperModule { }
