import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PublicDataComponent } from './public-data.component';
import { DataPrivacyComponent } from './data-privacy/data-privacy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { ImpressumComponent } from './impressum/impressum.component';


const routes: Routes = [
  { path: '', component: PublicDataComponent },
  { path: 'privacy', component: DataPrivacyComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'impressum', component: ImpressumComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicDataRoutingModule { }
