import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AccountService} from '../../_services/account.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  @Output() ResetStop: EventEmitter<any> = new EventEmitter<any>();
  emailForm: FormGroup;
  reset = false;

  constructor(
    private accountService: AccountService,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  get f() {return this.emailForm.controls; }

  initForm() {
    this.emailForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  back() {
    this.ResetStop.emit();
  }

  submit() {
    this.accountService.resetPassword(this.f.email.value).subscribe(
      () => {},
      () => {},
      () => {
        this.reset = true;
      }
    );
  }

}
