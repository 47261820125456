<ng-container *ngIf="loading">
  <div class="cf-loading-div">
    <div fxLayout fxLayoutAlign="center center" class="cf-loading-div">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!loading">
  <app-sidenav *ngIf="isLoggedIn" (logout)="logout()"></app-sidenav>
  <app-login *ngIf="!isLoggedIn && !startRegister && !startReset && !startPasswordReset && !isMailVerify && !isPublicData" (RegisterStart)="registerStarted()" (SuccessfullLogin)="getStatus()" (ResetStart)="resetStarted()"></app-login>
  <app-register *ngIf="startRegister" (RegisterStop)="registerStopped()" (SuccessfullRegister)="getStatus()"></app-register>
  <app-email-verification *ngIf="isMailVerify"></app-email-verification>
  <app-password-reset *ngIf="startReset" (ResetStop)="resetStopped()"></app-password-reset>
  <app-password-reset-token *ngIf="startPasswordReset" (PasswordResetStop)="passwordResetStopped()"></app-password-reset-token>
  <app-public-data *ngIf="isPublicData"></app-public-data>
</ng-container>
