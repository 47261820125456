<form *ngIf="stepThreeForm"  [formGroup]="stepThreeForm">
    <div class="padding">
      <mat-checkbox #playerCheck class="full-width padding">Ich möchte selbst trainieren.</mat-checkbox>
    </div>
  <div>
    <div *ngIf="playerCheck.checked">
      <div class="cf-player-skill">
        <p class="align-left">Bitte versuchen Sie ihre jetzige Spielstärke einzuschätzen, damit wir Sie besser einteilen können. Unsere Skala reicht von 1 - 10.</p>
        <mat-slider value="1" matInput class="full-width padding" #slider min="1" max="10" thumbLabel tickInterval="1" (input)="onSliderChange($event)" color="primary">
        </mat-slider>
        <div>{{playerSkill[slider.value - 1]}}</div>
      </div>

      <mat-label class="full-width">Ich möchte mich zu folgenden Trainingsarten anmelden: <br></mat-label>
      <div class="full-width margin">
        <mat-checkbox formControlName="privateTrainingBol">Einzeltraining</mat-checkbox>
        <mat-form-field *ngIf="f3.privateTrainingBol.value" class="full-width cf-m-t-20 cf-m-l-15">
          <mat-label>Gewünschte Trainings pro Woche: </mat-label>
          <mat-select value="1" formControlName="privateTrainingAmount">
            <mat-option value="1">1</mat-option>
            <mat-option value="2">2</mat-option>
            <mat-option value="3">3</mat-option>
            <mat-option value="4">4</mat-option>
            <mat-option value="5">5</mat-option>
            <mat-option value="6">6</mat-option>
            <mat-option value="7">7</mat-option>
          </mat-select>
          <mat-error>Fehlende Eingabe!</mat-error>
        </mat-form-field>
      </div>
      <div class="full-width margin">
        <mat-checkbox formControlName="groupTrainingBol">Gruppentraining</mat-checkbox>
        <mat-form-field *ngIf="f3.groupTrainingBol.value" class="full-width cf-m-t-20 cf-m-l-15">
          <mat-label>Gewünschte Teilnehmer pro Gruppe: </mat-label>
          <mat-select value="1" formControlName="groupTrainingPlayers">
            <mat-option value="2">2</mat-option>
            <mat-option value="3">3</mat-option>
            <mat-option value="4">4</mat-option>
          </mat-select>
          <mat-error>Fehlende Eingabe!</mat-error>
        </mat-form-field>
        <div *ngIf="f3.groupTrainingBol.value" class="cf-m-l-15">
          <mat-checkbox formControlName="flexible_with_group_size">Ich bin mit der Anzahl von Teilnehmern pro Gruppe flexibel.
          </mat-checkbox>
        </div>
        <mat-form-field *ngIf="f3.groupTrainingBol.value" class="full-width cf-m-l-15">
          <mat-label>Gewünschte Trainings pro Woche: </mat-label>
          <mat-select value="1" formControlName="groupTrainingAmount">
            <mat-option value="1">1</mat-option>
            <mat-option value="2">2</mat-option>
            <mat-option value="3">3</mat-option>
            <mat-option value="4">4</mat-option>
            <mat-option value="5">5</mat-option>
            <mat-option value="6">6</mat-option>
            <mat-option value="7">7</mat-option>
          </mat-select>
          <mat-error>Fehlende Eingabe!</mat-error>
        </mat-form-field>
      </div>
      <div class="full-width margin">
        <mat-checkbox formControlName="teamTrainingBol">Mannschaftstraining</mat-checkbox>
        <mat-form-field *ngIf="f3.teamTrainingBol.value" class="full-width cf-m-l-15 cf-m-t-20">
          <mat-label>Gewünschte Mannschaft: </mat-label>
          <mat-select value="1" formControlName="chosenTeam">
            <mat-option *ngFor="let group of selectGroups" [value]="group.value">{{group.viewValue}}</mat-option>
          </mat-select>
          <mat-error>Fehlende Eingabe!</mat-error>
        </mat-form-field>
      </div>
      <div class="full-width margin">
          <mat-radio-group aria-label="Select an option" formControlName="start_directly">
            <mat-radio-button class="cf-m-r-5" value="true">Ich möchte direkt trainieren.</mat-radio-button>
            <mat-radio-button value="false">Ich möchte ab nächster Saison trainieren ({{formatSeasonString(nextSeason.start_date)}})</mat-radio-button>
          </mat-radio-group>
      </div>
      <div *ngIf="f3.groupTrainingBol.value || f3.privateTrainingBol.value">
        <p>Zu welchen Zeiten ist ein Training möglich?</p>
        <p *ngIf="f3.teamTrainingBol.value">Termine für Mannschaftstraining stehen bereits fest und sind nicht wählbar.</p>
        <mat-slide-toggle class="full-width" #monday>Montag</mat-slide-toggle>
        <div *ngIf="monday.checked">
          <div *ngFor="let time of weekdays[0].times">
            <mat-checkbox [disabled]="time.overbooked" (change)="addToTimes(time.id)">{{time.start_time.substring(0, 5)}} - {{time.end_time.substring(0, 5)}}</mat-checkbox>
          </div>
        </div>
        <mat-slide-toggle class="full-width" #tuesday>Dienstag</mat-slide-toggle>
        <div *ngIf="tuesday.checked">
          <div *ngFor="let time of weekdays[1].times">
            <mat-checkbox [disabled]="time.overbooked" (change)="addToTimes(time.id)">{{time.start_time.substring(0, 5)}} - {{time.end_time.substring(0, 5)}}</mat-checkbox>
          </div>
        </div>
        <mat-slide-toggle class="full-width" #wednesday>Mittwoch</mat-slide-toggle>
        <div *ngIf="wednesday.checked">
          <div *ngFor="let time of weekdays[2].times">
            <mat-checkbox [disabled]="time.overbooked" (change)="addToTimes(time.id)">{{time.start_time.substring(0, 5)}} - {{time.end_time.substring(0, 5)}}</mat-checkbox>
          </div>
        </div>
        <mat-slide-toggle class="full-width" #thursday>Donnerstag</mat-slide-toggle>
        <div *ngIf="thursday.checked">
          <div *ngFor="let time of weekdays[3].times">
            <mat-checkbox [disabled]="time.overbooked" (change)="addToTimes(time.id)">{{time.start_time.substring(0, 5)}} - {{time.end_time.substring(0, 5)}}</mat-checkbox>
          </div>
        </div>
        <mat-slide-toggle class="full-width" #friday>Freitag</mat-slide-toggle>
        <div *ngIf="friday.checked">
          <div *ngFor="let time of weekdays[4].times">
            <mat-checkbox [disabled]="time.overbooked" (change)="addToTimes(time.id)">{{time.start_time.substring(0, 5)}} - {{time.end_time.substring(0, 5)}}</mat-checkbox>
          </div>
        </div>
        <mat-slide-toggle class="full-width" #saturday>Samstag</mat-slide-toggle>
        <div *ngIf="saturday.checked">
          <div *ngFor="let time of weekdays[5].times">
            <mat-checkbox [disabled]="time.overbooked" (change)="addToTimes(time.id)">{{time.start_time.substring(0, 5)}} - {{time.end_time.substring(0, 5)}}</mat-checkbox>
          </div>
        </div>
        <mat-slide-toggle class="full-width" #sunday>Sonntag</mat-slide-toggle>
        <div *ngIf="sunday.checked">
          <div *ngFor="let time of weekdays[6].times">
            <mat-checkbox [disabled]="time.overbooked" (change)="addToTimes(time.id)">{{time.start_time.substring(0, 5)}} - {{time.end_time.substring(0, 5)}}</mat-checkbox>
          </div>
        </div>
      </div>
      <div>
        <mat-form-field class="full-width padding">
          <mat-label>Persönliche Nachricht</mat-label>
          <textarea (input)="checkMessage()" matInput formControlName="message"></textarea>
        </mat-form-field>
        <mat-error class="cf-m-b-10" *ngIf="messageProblem">Ihre Nachricht enthält ein unerlaubtes Sonderzeichen! Erlaubte Sonderzeichen: !"#$%&'()*+,-./:;<=>?@\^_`|~</mat-error>
      </div>
    </div>
    <div class="padding">
      <mat-checkbox #agreement (click)="checkInvalid()" class="full-width padding">Ich habe die <a routerLink="/public-data/privacy" target="_blank">Datenschutzerklärung</a> gelesen und bin damit einverstanden. *</mat-checkbox>
    </div>
    <div class="text-center">
      <button mat-flat-button color="primary" type="button" (click)="stepThreeSubmit()" [disabled]="stepThreeForm.invalid || messageProblem || !agreement.checked">ABSCHICKEN</button>
      <button class="cancelButton" mat-button color="warn" type="button" (click)="backStepThree()">ZURÜCK</button>
    </div>
  </div>
</form>
