<form *ngIf="formLoaded" class="text-center" [formGroup]="stepTwoForm">
  <div>
    <mat-form-field class="prefix padding">
      <mat-label>Ländervorwahl *</mat-label>
      <input matInput formControlName="mobilePrefix">
      <mat-error *ngIf="f2.mobilePrefix.invalid">Vorwahl eingeben</mat-error>
    </mat-form-field>
    <mat-form-field class="mobile padding">
      <mat-label>Mobil *</mat-label>
      <input matInput formControlName="mobile">
      <mat-error *ngIf="f2.mobile.invalid">Korrekte Telefonnummer eingeben</mat-error>
    </mat-form-field>
    <mat-form-field class="prefix padding">
      <mat-label>Ländervorwahl</mat-label>
      <input matInput formControlName="privatePrefix">
      <mat-error *ngIf="f2.privatePrefix.invalid">Vorwahl eingeben</mat-error>
    </mat-form-field>
    <mat-form-field class="mobile padding">
      <mat-label>Festnetz</mat-label>
      <input matInput formControlName="phone">
      <mat-error *ngIf="f2.phone.invalid">Korrekte Telefonnummer eingeben</mat-error>
    </mat-form-field>
    <mat-form-field class="full-width padding">
      <mat-label>Straße und Hausnummer *</mat-label>
      <input matInput formControlName="street">
      <mat-error>Bitte eine Hausnummer angeben oder richtig formatieren</mat-error>
    </mat-form-field>
    <mat-form-field class="full-width padding">
      <mat-label>PLZ *</mat-label>
      <input matInput formControlName="plz">
      <mat-error *ngIf="f2.plz.invalid">Korrekte PLZ eingeben</mat-error>
    </mat-form-field>
    <mat-form-field class="full-width padding">
      <mat-label>Ort *</mat-label>
      <input matInput formControlName="city">
    </mat-form-field>
    <button mat-flat-button color="primary" type="button" (click)="stepTwoSubmit()" [disabled]="stepTwoForm.invalid">WEITER</button>
    <button class="cancelButton" mat-button color="warn" type="button" (click)="backStepTwo()">ZURÜCK</button>
  </div>
</form>
