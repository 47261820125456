<div class="cf-vert-center cf-bg-login">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <mat-card class="cf-center cf-login">
      <img mat-card-image src="../../assets/img/logo/be-tennis-logo.png" class="cf-logo">
      <mat-card-title>
        <h1 class="mat-h1 cf-h1">Login</h1>
      </mat-card-title>
      <mat-error *ngIf="wrongCredentials">E-Mail und/oder Passwort falsch</mat-error>
      <mat-form-field class="cf-login-field">
        <mat-label>E-Mail Adresse</mat-label>
        <input matInput type="email" id="inputEmail" formControlName="email" placeholder="E-Mail Adresse">
        <mat-error *ngIf="f.email.invalid">Gültige E-Mail Adresse eingeben</mat-error>
      </mat-form-field><br/>
      <mat-form-field class="cf-login-field">
        <mat-label>Passwort</mat-label>
        <input matInput type="password" id="inputPasswort" formControlName="password" placeholder="Passwort">
      </mat-form-field><br/>
      <div fxLayoutGap="10px">
        <button mat-raised-button type="submit" class="cf-button cf-m-r-5" color="primary">LOGIN</button>
        <button mat-raised-button class="cf-button w-130" color="primary" (click)="startRegister()">REGISTRIEREN</button>
      </div>
      <div class="cf-df-jc">
        <button mat-button color="primary" (click)="startReset()">Passwort vergessen?</button>
      </div>
      <div class="cf-itcp-pages">
        <div class="impressum"><a href="/public-data/impressum">Impressum</a> · <a href="/public-data/terms-and-conditions">AGB</a></div>
        <a href="/public-data/privacy" class="privacy">Datenschutzerklärung</a>
      </div>
      <div class="cf-m-t-20" *ngIf="loading">
        <mat-spinner class="center" diameter="40" color="primary">
        </mat-spinner>
      </div>
    </mat-card>
  </form>
</div>
