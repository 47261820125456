import { Injectable } from '@angular/core';
import {FormControl, ValidationErrors} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  calculateAge(dob: string): number {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  formatDate(date: string) {
    return date.slice(8, 10) + '.' + date.slice(5, 7) + '.' + date.slice(0, 4);
  }

  checkForSpecialCharacters(message: string): boolean {
    return message.match(/^[a-zA-Z0-9ßäüöÄÖÜ!"#$%&'()*+,-./:;<=>?@\^_`{|}~\s]+$/) ? true : false;
  }

  checkForValidStreet(control: FormControl): ValidationErrors {

    if (control.value === null) { return null; }
    return control.value.toString().match(/^[a-z, A-Z, ß, ü, ö, ä._#-]*\s+[0-9]+\s*\w*/gm) ? null : {
      checkForValidStreet: {
        valid: false
      }
    };
  }
}
