import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidenavModule } from './sidenav/sidenav.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtInterceptor} from './_helpers/jwt.interceptor';
import {ErrorInterceptor} from './_helpers/error.interceptor';
import { HttpClientModule} from '@angular/common/http';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DatePipe, Location} from '@angular/common';
import {LoginModule} from './login/login.module';
import {RegisterModule} from './register/register.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import {PasswordResetTokenModule} from './password-reset-token/password-reset-token.module';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {EmailVerificationModule} from './email-verification/email-verification.module';
import {PublicDataModule} from './public-data/public-data.module';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";



@NgModule({
  declarations: [
    AppComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SidenavModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        LoginModule,
        RegisterModule,
        FlexLayoutModule,
        PasswordResetTokenModule,
        EmailVerificationModule,
        PublicDataModule,
        MatProgressSpinnerModule,
    ],
  providers: [DatePipe, Location, MatDatepickerModule,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE'}],
  bootstrap: [AppComponent]

})
export class AppModule { }
