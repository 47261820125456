<div *ngIf="player.level_self !== null" [formGroup]="formThree">
  <div class="cf-player-skill">
    <p class="align-left"><strong>Geschätzte Spielstärke</strong></p>
    <mat-slider value="1" class="full-width padding" #slider min="1" max="10" thumbLabel tickInterval="1" (input)="onSliderChange($event)" color="primary" [value]="player.level_self">
    </mat-slider>
    <div>{{playerSkill[slider.value - 1]}}</div>
  </div>
  <div fxLayout="column" fxLayoutGap="10px">
    <div>
      <mat-checkbox formControlName="singleTrainingBol">Einzeltraining</mat-checkbox>
    </div>
    <div *ngIf="formThree.get('singleTrainingBol').value" fxFlex="100" class="cf-m-l-15">
      <mat-form-field fxFlex="100">
        <mat-label>Gewünschte Trainings pro Woche:</mat-label>
        <mat-select formControlName="singleTrainingNum">
          <mat-option value="1">1</mat-option>
          <mat-option value="2">2</mat-option>
          <mat-option value="3">3</mat-option>
          <mat-option value="4">4</mat-option>
          <mat-option value="5">5</mat-option>
          <mat-option value="6">6</mat-option>
          <mat-option value="7">7</mat-option>
        </mat-select>
        <mat-error>Fehlende Eingabe!</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="column" fxLayoutGap="10px">
    <div>
      <mat-checkbox formControlName="groupTrainingBol">Gruppentraining</mat-checkbox>
    </div>
    <div *ngIf="formThree.get('groupTrainingBol').value" class="cf-m-l-15">
      <mat-form-field fxFlex="100">
        <mat-label>Gewünschte Teilnehmer pro Gruppe:</mat-label>
        <mat-select formControlName="groupTrainingGroupSize" [value]="this.player.pref_group_size">
          <mat-option value="2">2</mat-option>
          <mat-option value="3">3</mat-option>
          <mat-option value="4">4</mat-option>
        </mat-select>
        <mat-error>Fehlende Eingabe!</mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="formThree.get('groupTrainingBol').value" class="flexible-checkbox cf-m-l-15">
      <mat-checkbox formControlName="flexible_with_group_size">Ich bin mit der Anzahl von Teilnehmern pro Gruppe flexibel.
      </mat-checkbox>
    </div>
    <div *ngIf="formThree.get('groupTrainingBol').value" class="cf-m-l-15">
      <mat-form-field fxFlex="100">
        <mat-label>Gewünschte Trainings pro Woche:</mat-label>
        <mat-select formControlName="groupTrainingNum">
          <mat-option value="1">1</mat-option>
          <mat-option value="2">2</mat-option>
          <mat-option value="3">3</mat-option>
          <mat-option value="4">4</mat-option>
          <mat-option value="5">5</mat-option>
          <mat-option value="6">6</mat-option>
          <mat-option value="7">7</mat-option>
        </mat-select>
        <mat-error>Fehlende Eingabe!</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="column" fxLayoutGap="10px">
    <div>
      <mat-checkbox formControlName="teamTrainingBol">Mannschaftstraining</mat-checkbox>
    </div>
    <div *ngIf="formThree.get('teamTrainingBol').value" class="cf-m-l-15">
      <mat-form-field fxFlex="100">
        <mat-label>Gewünschte Mannschaft:</mat-label>
        <mat-select value="1" formControlName="selectedTeam">
          <mat-option *ngFor="let group of selectGroups" [value]="group.value">{{group.viewValue}}</mat-option>
        </mat-select>
        <mat-error>Fehlende Eingabe!</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="formThree.get('singleTrainingBol').value === true || formThree.get('groupTrainingBol').value === true">
     <p>Zu welchen Zeiten ist ein Training möglich?</p>
    <mat-slide-toggle [checked]="mondayPrefTime" class="full-width" #monday>Montag</mat-slide-toggle>
    <div *ngIf="monday.checked">
      <div *ngFor="let time of weekdays[0].times">
        <mat-checkbox [disabled]="time.overbooked && (!player.pref_times.includes(time.id))" [checked]="player.pref_times.includes(time.id)" (change)="addToTimes(time.id)">{{time.start_time.substring(0, 5)}} - {{time.end_time.substring(0, 5)}}</mat-checkbox>
      </div>
    </div>
    <mat-slide-toggle [checked]="tuesdayPrefTime" class="full-width" #tuesday>Dienstag</mat-slide-toggle>
    <div *ngIf="tuesday.checked">
      <div *ngFor="let time of weekdays[1].times">
        <mat-checkbox [disabled]="time.overbooked && (!player.pref_times.includes(time.id))" [checked]="player.pref_times.includes(time.id)" (change)="addToTimes(time.id)">{{time.start_time.substring(0, 5)}} - {{time.end_time.substring(0, 5)}}</mat-checkbox>
      </div>
    </div>
    <mat-slide-toggle [checked]="wednesdayPrefTime" class="full-width" #wednesday>Mittwoch</mat-slide-toggle>
    <div *ngIf="wednesday.checked">
      <div *ngFor="let time of weekdays[2].times">
        <mat-checkbox [disabled]="time.overbooked && (!player.pref_times.includes(time.id))" [checked]="player.pref_times.includes(time.id)" (change)="addToTimes(time.id)">{{time.start_time.substring(0, 5)}} - {{time.end_time.substring(0, 5)}}</mat-checkbox>
      </div>
    </div>
    <mat-slide-toggle [checked]="thursdayPrefTime" class="full-width" #thursday>Donnerstag</mat-slide-toggle>
    <div *ngIf="thursday.checked">
      <div *ngFor="let time of weekdays[3].times">
        <mat-checkbox [disabled]="time.overbooked && (!player.pref_times.includes(time.id))" [checked]="player.pref_times.includes(time.id)" (change)="addToTimes(time.id)">{{time.start_time.substring(0, 5)}} - {{time.end_time.substring(0, 5)}}</mat-checkbox>
      </div>
    </div>
    <mat-slide-toggle [checked]="fridayPrefTime" class="full-width" #friday>Freitag</mat-slide-toggle>
    <div *ngIf="friday.checked">
      <div *ngFor="let time of weekdays[4].times">
        <mat-checkbox [disabled]="time.overbooked && (!player.pref_times.includes(time.id))" [checked]="player.pref_times.includes(time.id)" (change)="addToTimes(time.id)">{{time.start_time.substring(0, 5)}} - {{time.end_time.substring(0, 5)}}</mat-checkbox>
      </div>
    </div>
    <mat-slide-toggle [checked]="saturdayPrefTime" class="full-width" #saturday>Samstag</mat-slide-toggle>
    <div *ngIf="saturday.checked">
      <div *ngFor="let time of weekdays[5].times">
        <mat-checkbox [disabled]="time.overbooked && (!player.pref_times.includes(time.id))" [checked]="player.pref_times.includes(time.id)" (change)="addToTimes(time.id)">{{time.start_time.substring(0, 5)}} - {{time.end_time.substring(0, 5)}}</mat-checkbox>
      </div>
    </div>
    <mat-slide-toggle [checked]="sundayPrefTime" class="full-width" #sunday>Sonntag</mat-slide-toggle>
    <div *ngIf="sunday.checked">
      <div *ngFor="let time of weekdays[6].times">
        <mat-checkbox [disabled]="time.overbooked && (!player.pref_times.includes(time.id))" [checked]="player.pref_times.includes(time.id)" (change)="addToTimes(time.id)">{{time.start_time.substring(0, 5)}} - {{time.end_time.substring(0, 5)}}</mat-checkbox>
      </div>
    </div>
    </div>
</div>
<div *ngIf="player.level_self === null">
  <button (click)="saveData()" mat-raised-button color="primary">Erstelle ein Spieler Profil!</button>
</div>
