//export const BASE_URL = 'https://backend.betennis.net/';
export const BASE_URL = 'http://backend.cloudfood.django.group/';
// export const BASE_URL = 'http://127.0.0.1:8000/';

export const PLAYER_SKILL = [
  'Ich habe keinerlei Erfahrung im Tennisbereich oder mit verwandten Ballsportarten wie Fußball, Basketball oder Hockey',
  'Ich habe noch nie Tennis gespielt aber bereits Erfahrung in einer anderen Ballsportart gesammelt',
  'Ich habe bereits schon einmal Tennis gespielt, bewege mich aber noch im Anfängerbereich',
  'Ich habe bereits grundlegende technische Fertigkeiten in den verschiedenen Schlägen Aufschlag, Vorhand und Rückhand',
  'Ich traue mir zu, mit einem adäquaten Spielpartner Ballwechsel über das Netz durchzuführen',
  'Ich kann das gesamte Spielfeld abdecken und habe Kontrolle über meine Schläge',
  'Ich kann die Richtung, die Geschwindigkeit und die Länge meiner Schläge kontrollieren',
  'Ich kann meinen Schlägen Vorwärts- oder Rückwärtsdrall verleihen und komme mit verschiedenen Ballgeschwindigkeiten des Gegners zurecht',
  'Ich kann in meinem Spiel Strategie und Spielart variieren und damit taktisch einsetzen',
  'Ich beherrsche das gesamte Schlagrepertoire und kann meinen Gegner damit im Spiel unter Druck setzen'
];
