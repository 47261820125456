import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Person} from '../../_classes/person';
import {Location} from '@angular/common';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';



export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

interface Select {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-step-one-register',
  templateUrl: './step-one-register.component.html',
  styleUrls: ['./step-one-register.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
],
})
export class StepOneRegisterComponent implements OnInit {

  @Output() SubmitStepOne: EventEmitter<any> = new EventEmitter<any>();
  @Output() BackStepOne: EventEmitter<any> = new EventEmitter<any>();

  @Input() person: Person;

  maxDate: Date;

  smallPhoto: any;
  photo: any;

  photoChanged = false;
  get f1() {
    return this.stepOneForm.controls;
  }


  startDate = new Date(1990, 1, 1);
  genders: Select[] = [
    {value: 'm', viewValue: 'm'},
    {value: 'w', viewValue: 'w'}
  ];
  nationalities: Select[] = [
    {value: 'Deutschland', viewValue: 'Deutschland'},
    {value: 'Österreich', viewValue: 'Österreich'},
    {value: 'Schweiz', viewValue: 'Schweiz'},
    {value: 'Tschechien', viewValue: 'Tschechien'},
    {value: 'Italien', viewValue: 'Italien'},
    {value: 'Andere', viewValue: 'Andere'},
  ];

  formLoaded = false;
  data: any;
  stepOneForm: FormGroup;

  secondPassword: string;


  // Validator for password verfication
  matchValues(matchTo: string): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
      !!control.parent.value &&
      control.value === control.parent.controls[matchTo].value
        ? null
        : {isMatching: false};
    };
  }

  calculateAge(dob: string): number {
    const today = new Date();
    let age = today.getFullYear() - +dob.slice(0, 4);
    const month = today.getMonth() - +dob.slice(6, 7);
    if (month < 0 || (month === 0 && today.getDate() < +dob.slice(9, 10))) {
      age--;
    }
    return age;
  }

  back() {
    this.location.back();
    this.BackStepOne.emit();
  }

  onFileChanged(event) {
    const file = event.target.files[0];
    const uploadData = new FormData();
    uploadData.append('myFile', file, file.name);
  }

  savePhotos(data: any) {
    this.smallPhoto = data.smallPhoto;
    this.photo = data.photo;
    console.log(data);
    this.photoChanged = true;
  }

  stepOneSubmit() {
    this.data = {
      firstName: this.f1.firstName.value,
      lastName: this.f1.lastName.value,
      email: this.f1.email.value,
      bday: this.f1.bday.value,
      gender: this.f1.gender.value,
      nationality: this.f1.nationality.value,
      password: this.f1.password.value,
      photo: this.photo,
      smallPhoto: this.smallPhoto,
      photoChanged: this.photoChanged,
      rtk: this.f1.rtk.value
    };
    if (this.f1.nationality.value === 'Andere') {
      this.data.nationality = this.f1.differentNationality.value;
    }
    this.SubmitStepOne.emit(this.data);

  }

  initForm() {
    this.stepOneForm = new FormGroup({
      firstName: new FormControl(this.person.first_name, [Validators.required]),
      lastName: new FormControl(this.person.last_name, [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      decoyEmail: new FormControl(''),
      bday: new FormControl(this.person.date_of_birth, [Validators.required]),
      gender: new FormControl(this.person.sex, [Validators.required]),
      nationality: new FormControl(this.person.nationality, [Validators.required]),
      differentNationality: new FormControl(''),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      secondPassword: new FormControl('', [this.matchValues('password')]),
      photo: new FormControl(this.person.photo),
      rtk: new FormControl('', [Validators.required])
    }, {validators: otherNationValidator});
    this.stepOneForm.controls.password.valueChanges.subscribe(() => {
      this.stepOneForm.controls.secondPassword.updateValueAndValidity();
    });
  }
  constructor(
    private location: Location,
  ) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDate();
    this.maxDate = new Date(currentYear - 18, currentMonth, currentDay);
    const date = new Date();
    console.log(this.maxDate);
  }

  ngOnInit(): void {
    this.initForm();
    this.formLoaded = true;
  }

}

export const otherNationValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const nationality = control.get('nationality');
  const differentNationality = control.get('differentNationality');

  if (nationality.value === 'Andere' && differentNationality.value === '') {
    return {otherNation: true};
  }
  return null;
};

