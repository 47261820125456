import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PasswordResetTokenComponent } from './password-reset-token.component';

const routes: Routes = [{ path: '', component: PasswordResetTokenComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PasswordResetTokenRoutingModule { }
