import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {BASE_URL} from '../globals';
import {UrlService} from './url.service';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  baseUrl = BASE_URL;


  constructor(
    private http: HttpClient,
    private urlService: UrlService
  ) {
    this.baseUrl = this.urlService.getURL();
  }

  getGroups(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/user-requests/public-team-list/`).pipe(
      tap(_ => console.log('fetched all groups'))
    );
  }
}
