import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {BASE_URL} from '../globals';
import {UrlService} from './url.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  baseUrl = BASE_URL;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private urlService: UrlService
  ) {
    this.baseUrl = this.urlService.getURL();
  }

  private myFunc: (error: any) => void;
  onBadPassword(fn: () => void) {
    this.myFunc = fn;
  }

  registerAccount(e: string, p: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}rest-auth/registration/`, {email: e, password1: p, password2: p}).pipe(
      tap(tokens => this.authenticationService.doLoginUser(e, tokens),
        error => {
        this.myFunc(error);},
        () => {})
    );
  }

  addPerson(data: any): Observable<any> {
    return this.http.put(`${this.baseUrl}api/user-requests/update-registration/`, data).pipe(
      tap(_ => console.log('added Person'),
        error => this.myFunc(error)),
      catchError(this.handleError<any>('addPerson'))
    );
  }

  createMainPlayer(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/user-requests/create-main-player/`, data).pipe(
      tap(() => console.log('created Main Player'),
        error => {
        this.myFunc(error);
        },
        () => {}),
      catchError(this.handleError<any>('registerAccount'))
    );
  }
  getTimes(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/user-requests/pref-times/`).pipe(
      tap(_ => console.log('fetched pref_times'))
    );
  }

  getNextSeason(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/user-requests/next-season/`).pipe(
      tap(_ => console.log('fetched next season'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result
      return of(result as T);
    };
  }
}
