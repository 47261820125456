import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Person} from '../../_classes/person';
import {UtilsService} from "../../_services/utils.service";

@Component({
  selector: 'app-step-two-data',
  templateUrl: './step-two-data.component.html',
  styleUrls: ['./step-two-data.component.scss']
})
export class StepTwoDataComponent implements OnInit {

  @Output() SubmitStepTwo: EventEmitter<any> = new EventEmitter<any>();
  @Output() BackStepTwo: EventEmitter<any> = new EventEmitter<any>();

  @Input() person: Person;

  stepTwoForm: FormGroup;
  data: any;

  formLoaded = false;

  get f2() { return this.stepTwoForm.controls; }

  initForm() {
    this.stepTwoForm = new FormGroup({
      mobile: new FormControl(this.person.phone_mobile.toString(), [Validators.required, Validators.pattern('^[+][0-9]*$'), Validators.maxLength(14)]),
      phone: new FormControl(this.person.phone_private, [Validators.pattern('^([-]|[+][0-9]*|[0-9]*)$'), Validators.maxLength(14)]),
      street: new FormControl(this.person.street, [Validators.required, this.utilsService.checkForValidStreet]),
      plz: new FormControl(this.person.zip, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(5)]),
      city: new FormControl(this.person.city, [Validators.required])
    });
  }
  constructor(
    private utilsService: UtilsService,
  ) { }

  ngOnInit() {
    this.initForm();
    this.formLoaded = true;
  }

}
