import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UtilsService} from '../../_services/utils.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {

  // variables
  messageProblem: boolean;
  commentForm: FormGroup;
  savedComment: string;

  // inputs and outputs
  @Input() comment: string;
  @Input() commentInputBol: boolean;

  @Output() newCommentSubmit: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('commentInput') public commentInput: ElementRef;


  constructor(
    private utilsService: UtilsService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.savedComment = this.comment;
    if (this.commentInputBol) {
      setTimeout(() => {
        this.commentInput.nativeElement.focus();
      }, 800)
    }
  }

  initForm() {
    this.commentForm = this.fb.group({
      comment: [this.comment, [Validators.maxLength(499)]]
    });
  }

  checkMessage() {
    console.log('checking message');
    if (this.commentForm.controls.comment.value) {
      this.messageProblem = !this.utilsService.checkForSpecialCharacters(this.commentForm.controls.comment.value);
      if (!this.messageProblem && this.savedComment !== this.commentForm.controls.comment.value && this.commentForm.valid) {
        if (this.commentForm.controls.comment.value !== '') {
          this.newCommentSubmit.emit({newComment: true, comment: this.commentForm.controls.comment.value});
        }
      } else {
        this.newCommentSubmit.emit({newComment : false});
      }
    } else if (this.commentForm.controls.comment.value === '') {
      console.log('commited empty message');
      this.newCommentSubmit.emit({newComment: true, comment: null});
    } else {
      this.messageProblem = false;
    }
  }

}
