import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedDataRoutingModule } from './shared-data-routing.module';
import { AngularMaterialModule } from '../-angular-material/-angular-material.module';
import { SharedDataComponent } from './shared-data.component';
import { StepOneDataComponent } from './step-one-data/step-one-data.component';
import { StepTwoDataComponent } from './step-two-data/step-two-data.component';
import { StepThreeDataComponent } from './step-three-data/step-three-data.component';
import {ReactiveFormsModule} from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import {FlexModule} from '@angular/flex-layout';
import { SharedImageCropperModule } from '../shared-image-cropper/shared-image-cropper.module';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { CommentComponent } from './comment/comment.component';


@NgModule({
  declarations: [SharedDataComponent, StepOneDataComponent, StepTwoDataComponent, StepThreeDataComponent, CommentComponent],
  exports: [
    SharedDataComponent
  ],
    imports: [
      CommonModule,
      SharedDataRoutingModule,
      AngularMaterialModule,
      ReactiveFormsModule,
      ImageCropperModule,
      FlexModule,
      SharedImageCropperModule,
      MatSnackBarModule
    ]
})
export class SharedDataModule { }
