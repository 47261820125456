import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-data',
  templateUrl: './public-data.component.html',
  styleUrls: ['./public-data.component.scss']
})
export class PublicDataComponent implements OnInit {

  isImpressum = false;
  isTermsAndConditions = false;
  isPrivacy = false;

  constructor() {
    this.checkUrl();
  }

  checkUrl() {
    const url = window.location.href;
    // check if the url contains 'email-verification'
    if (url.indexOf('impressum') !== -1) {
      this.isImpressum = true;
    } else if (url.indexOf('terms-and-conditions') !== -1) {
      this.isTermsAndConditions = true;
    } else {
      this.isPrivacy = true;
    }
  }

  ngOnInit(): void {
  }

}
