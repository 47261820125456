<mat-card>
  <div fxLayout.gt-xs="row" fxLayoutGap="10px" fxLayout="column" class="cf-upload-button">
    <div class="cf-vert-center">Laden Sie ein neues Foto hoch</div>
    <div>
      <button mat-button color="warn" (click)="fileUpload.click(); uploadBol = true" (change)="fileChangeEvent($event)">
        <mat-icon>file_upload</mat-icon>
        Foto hochladen
      </button>
    </div>
  </div>
  <div class="cf-text-center">
    <mat-icon *ngIf="fileSaved" [ngStyle]="{'color':'#32CD32', 'margin-left': '20px'}">done</mat-icon>
  </div>
  <input #fileUpload type="file" (change)="fileChangeEvent($event)" style="display: none; max-height: 200px;"/>
  <image-cropper
    *ngIf="!fileSaved && fileChanged"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="1 / 1"
    format="jpeg"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
    resizeToHeight="300"
    resizeToWidth="300"
    class="cf-image-cropper">
  </image-cropper>
  <div class="cf-text-center">
    <button *ngIf="fileChanged && !fileSaved" mat-raised-button (click)="uploadPhotos(); fileSaved = true; uploadBol = false" color="primary">Foto bestätigen</button>
  </div>
  <div style="margin-left: -9999px; height: 1px;">
    <image-cropper
      imageBase64="{{croppedImage}}"
      [maintainAspectRatio]="true"
      [aspectRatio]="1 / 1"
      format="jpeg"
      (imageCropped)="smallImageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
      resizeToHeight="100"
      resizeToWidth="100">
    </image-cropper>
  </div>
</mat-card>
