import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Person} from '../../_classes/person';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {APP_DATE_FORMATS, AppDateAdapter} from '../../_helpers/format-datepicker';

interface Select {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-step-one-data',
  templateUrl: './step-one-data.component.html',
  styleUrls: ['./step-one-data.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class StepOneDataComponent implements OnInit {

  @Input() person: Person;

  formData = new FormData();

  fileChanged = false;

  get f1() {
    return this.stepOneForm.controls;
  }

  startDate = new Date(1990, 1, 1);
  genders: Select[] = [
    {value: 'm', viewValue: 'm'},
    {value: 'w', viewValue: 'w'}
  ];
  nationalities: Select[] = [
    {value: 'Deutschland', viewValue: 'Deutschland'},
    {value: 'Österreich', viewValue: 'Österreich'},
    {value: 'Schweiz', viewValue: 'Schweiz'},
    {value: 'Tschechien', viewValue: 'Tschechien'},
    {value: 'Italien', viewValue: 'Italien'},
    {value: 'Andere', viewValue: 'Andere'}
  ];

  data: any;
  stepOneForm: FormGroup;
  croppedImage: any;
  smallCroppedImage: any;
  formLoaded = false;

  maxDate: any;

  initForm() {
    let nationality = 'Andere';
    for (const nat of this.nationalities) {
      if (this.person.nationality === nat.value) {
        nationality = this.person.nationality;
      }
    }
    this.stepOneForm = new FormGroup({
      firstName: new FormControl(this.person.first_name, [Validators.required]),
      lastName: new FormControl(this.person.last_name, [Validators.required]),
      bday: new FormControl(this.person.date_of_birth, [Validators.required]),
      gender: new FormControl(this.person.sex, [Validators.required]),
      nationality: new FormControl(nationality, [Validators.required]),
      differentNationality: new FormControl(this.person.nationality, [Validators.required]),
      rtk: new FormControl(this.person.rtk, [Validators.required])
    });
  }
  constructor() {
    this.maxDate = new Date();
  }

  ngOnInit(): void {

    console.log(this.person);
    this.initForm();
    this.formLoaded = true;
  }

  savePhotos(data: any) {
    this.fileChanged = true;
    this.croppedImage = data.photo;
    this.smallCroppedImage = data.smallPhoto;
    this.formData.append(this.person.first_name + this.person.last_name + '.png', this.croppedImage);
  }
}

