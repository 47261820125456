import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {PhotosService} from '../_services/photos.service';
import {PlayersService} from '../_services/players.service';

@Component({
  selector: 'app-shared-image-cropper',
  templateUrl: './shared-image-cropper.component.html',
  styleUrls: ['./shared-image-cropper.component.scss']
})
export class SharedImageCropperComponent implements OnInit {

  formLoaded = false;
  data: any;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  smallCroppedImage: any = '';

  fileChanged = false;
  fileSaved = false;

  uploadBol = false;

  @Output() SubmitData: EventEmitter<any> = new EventEmitter<any>();

  constructor(
  ) { }

  ngOnInit(): void {
    this.formLoaded = true;
  }

  uploadPhotos() {
    this.SubmitData.emit({smallPhoto: this.smallCroppedImage, photo: this.croppedImage});
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.fileSaved = false;
    this.fileChanged = true;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  smallImageCropped(event: ImageCroppedEvent) {
    this.smallCroppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

}
