import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Person} from '../../_classes/person';
import {RegistrationService} from '../../_services/registration.service';
import {GroupService} from '../../_services/group.service';
import {PLAYER_SKILL} from '../../globals';
import {UtilsService} from "../../_services/utils.service";

@Component({
  selector: 'app-step-three-register',
  templateUrl: './step-three-register.component.html',
  styleUrls: ['./step-three-register.component.scss']
})
export class StepThreeRegisterComponent implements OnInit {

  @Output() SubmitStepThree: EventEmitter<any> = new EventEmitter<any>();
  @Output() BackStepThree: EventEmitter<any> = new EventEmitter<any>();

  @Input() person: Person;
  @ViewChild('playerCheck') playerCheck: any;

  formLoaded = false;

  stepThreeForm: FormGroup;
  strength = 1;
  teamTrainingBol: boolean;
  team: string;
  message: string;
  data: any;
  playerBol = false;

  times: any;
  pref_times = [];

  i: number;

  playerSkill;

  weekdays = [];
  monday = {day: 'Montag', times: []};
  tuesday = {day: 'Dienstag', times: []};
  wednesday = {day: 'Mittwoch', times: []};
  thursday = {day: 'Donnerstag', times: []};
  friday = {day: 'Freitag', times: []};
  saturday = {day: 'Samstag', times: []};
  sunday = {day: 'Sonntag', times: []};

  groups = [];
  selectGroups = [];

  nextSeason: any;

  messageField: string;
  messageProblem = false;

  get f3() { return this.stepThreeForm.controls; }

  onSliderChange(slider: any) {
    this.strength = slider.value;
  }

  initForm() {
    this.stepThreeForm = new FormGroup({
      playerBol: new FormControl(this.playerBol),
      teamTrainingBol: new FormControl(false),
      groupTrainingBol: new FormControl(false),
      privateTrainingBol: new FormControl(false),
      privateTrainingAmount: new FormControl(''),
      groupTrainingPlayers: new FormControl(''),
      groupTrainingAmount: new FormControl(''),
      chosenTeam: new FormControl('keine Angabe'),
      // team: new FormControl(this.team, [Validators.required]),
      message: new FormControl(this.person.message, [Validators.maxLength(499)]),
      flexible_with_group_size: new FormControl(true),
      start_directly: new FormControl('true')
    });
    this.setupValidatorListeners();
  }
  constructor(
    private registrationService: RegistrationService,
    private groupService: GroupService,
    private utilsService: UtilsService,
  ) { }

  ngOnInit() {
    this.playerSkill = PLAYER_SKILL;
    this.getPrefTimes();
    this.getGroups();
    this.getNextSeason();
  }

  setupValidatorListeners() {
    this.stepThreeForm.controls.privateTrainingBol.valueChanges.subscribe(
      value => {
        if (value) {
          this.stepThreeForm.controls.privateTrainingAmount.setValidators(Validators.required);
        } else {
          this.stepThreeForm.controls.privateTrainingAmount.clearValidators();
        }
        this.stepThreeForm.controls.privateTrainingAmount.updateValueAndValidity();
      }
    );
    this.stepThreeForm.controls.groupTrainingBol.valueChanges.subscribe(
      value => {
        if (value) {
          this.stepThreeForm.controls.groupTrainingPlayers.setValidators(Validators.required);
          this.stepThreeForm.controls.groupTrainingAmount.setValidators(Validators.required);
        } else {
          this.stepThreeForm.controls.groupTrainingPlayers.clearValidators();
          this.stepThreeForm.controls.groupTrainingAmount.clearValidators();
        }
        this.stepThreeForm.controls.groupTrainingAmount.updateValueAndValidity();
        this.stepThreeForm.controls.groupTrainingPlayers.updateValueAndValidity();
      }
    );
    this.stepThreeForm.controls.teamTrainingBol.valueChanges.subscribe(
      value => {
        if (value) {
          this.stepThreeForm.controls.chosenTeam.setValidators(Validators.required);
        } else {
          this.stepThreeForm.controls.chosenTeam.clearValidators();
        }
        this.stepThreeForm.controls.chosenTeam.updateValueAndValidity();
      }
    );
  }

  getNextSeason() {
    this.registrationService.getNextSeason().subscribe(
      nextSeason => this.nextSeason = nextSeason,
    );
  }

  formatSeasonString(season: string) {
    const year = season.substring(0, 4);
    const month = season.substring(5, 7);
    const date = season.substring(8, 10);

    return date + '.' + month + '.' + year;
}

  getGroups() {
    this.groupService.getGroups().subscribe(
      groups => this.groups = groups,
      () => {},
      () => {
        this.initGroupSelect();
      }
    );
  }

  initGroupSelect() {
    this.selectGroups.push({value: 'keine Angabe', viewValue: 'keine Angabe'});
    for (const group of this.groups) {
      this.selectGroups.push({value: group.id, viewValue: group.name});
    }
  }

  checkMessage() {
    console.log('checking message');
    if (this.f3.message.value && this.f3.message.value !== '') {
      this.messageProblem = !this.utilsService.checkForSpecialCharacters(this.f3.message.value);
    } else {
      this.messageProblem = false;
    }
  }

  getPrefTimes() {
    this.registrationService.getTimes().subscribe(
      times => this.times = times,
      () => {},
      () => {console.log(this.times);
             this.sortTimes();
             this.initForm();
             this.formLoaded = true; }
    );
  }

  sortTimes() {
    for ( this.i = 0; this.i < this.times.length; this.i++) {
      switch (this.times[this.i].day) {
        case 0:
          this.monday.times.push(this.times[this.i]);
          break;
        case 1:
          this.tuesday.times.push(this.times[this.i]);
          break;
        case 2:
          this.wednesday.times.push(this.times[this.i]);
          break;
        case 3:
          this.thursday.times.push(this.times[this.i]);
          break;
        case 4:
          this.friday.times.push(this.times[this.i]);
          break;
        case 5:
          this.saturday.times.push(this.times[this.i]);
          break;
        case 6:
          this.sunday.times.push(this.times[this.i]);
          break;
      }
    }
    if (this.monday.times.length !== 0) {this.weekdays.push(this.monday); }
    if (this.tuesday.times.length !== 0) {this.weekdays.push(this.tuesday); }
    if (this.wednesday.times.length !== 0) {this.weekdays.push(this.wednesday); }
    if (this.thursday.times.length !== 0) {this.weekdays.push(this.thursday); }
    if (this.friday.times.length !== 0) {this.weekdays.push(this.friday); }
    if (this.saturday.times.length !== 0) {this.weekdays.push(this.saturday); }
    if (this.sunday.times.length !== 0) {this.weekdays.push(this.sunday); }
  }

  addToTimes(id: number) {
    if (!this.pref_times.includes(id)) {
      this.pref_times.push(id);
    } else {
      const index = this.pref_times.indexOf(id);
      this.pref_times.splice(index, 1);
    }
    console.log(this.pref_times);
  }

  stepThreeSubmit() {
    if (this.playerCheck.checked === false) {
      this.SubmitStepThree.emit({state: false, message: this.f3.message.value});
    } else {
      const _privateTraining = this.f3.privateTrainingBol.value ? this.f3.privateTrainingAmount.value : undefined;
      const _groupTraining = this.f3.groupTrainingBol.value ? this.f3.groupTrainingAmount.value : undefined;
      const _groupTrainingPlayers = this.f3.groupTrainingBol.value ? this.f3.groupTrainingPlayers.value : undefined;
      const _teamTraining = this.f3.teamTrainingBol.value;
      const _chosenTeam = (this.f3.teamTrainingBol.value && typeof this.f3.chosenTeam.value !== 'string') ? this.f3.chosenTeam.value : undefined;
      this.data = {
        state: true,
        chosenTeam: _chosenTeam,
        teamTraining: _teamTraining,
        privateTraining: _privateTraining,
        groupTraining: _groupTraining,
        groupTrainingPlayers: _groupTrainingPlayers,
        message: this.f3.message.value,
        pref_times: this.pref_times,
        level_self: this.strength,
        start_directly: this.f3.start_directly.value,
        flexible_with_group_size: this.f3.flexible_with_group_size.value,
      };
      this.SubmitStepThree.emit(this.data);
    }
  }
  backStepThree() {
    this.BackStepThree.emit();
  }

  checkInvalid() {
    console.log(this.stepThreeForm);
    console.log(this.stepThreeForm.valid, this.messageProblem);
  }

}
