import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Training } from '../_classes/training';
import {BASE_URL} from '../globals';
import {UrlService} from './url.service';


@Injectable({
  providedIn: 'root'
})
export class TrainingService {

  baseUrl = BASE_URL;

  constructor(
    private http: HttpClient,
    private urlService: UrlService
  ) {
    this.baseUrl = this.urlService.getURL();
  }

  getCurrentSeason(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/user-requests/current-season/`).pipe(
      tap(_ => console.log('fetched current Season Info'))
    );
  }

  getNextSeason(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/user-requests/next-season/`).pipe(
      tap(_ => console.log('fetched next Season Info'))
    );
  }

  getTrainings(): Observable<Training[]> {
    return this.http.get<Training[]>(`${this.baseUrl}api/user-requests/trainings/`).pipe(
      tap(_ => console.log('fetched all trainings'))
    );
  }

  getTrainingsNextSeason(): Observable<Training[]> {
    return this.http.get<Training[]>(`${this.baseUrl}api/user-requests/trainings-next-season/`).pipe(
      tap(_ => console.log('fetched all trainings from next season'))
    );
  }

  getTrainerTrainings(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/user-requests/coach-trainings/`).pipe(
      tap(_ => console.log('fetched trainings of trainer'))
    );
  }

  getTrainingStates(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/admin-requests/training-state/`).pipe(
      tap(_ => console.log('fetched training states'))
    );
  }

  checkTrainer(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/user-requests/is-coach/`).pipe(
      tap(_ => console.log('checked if trainer'))
    );
  }

  getPrefTimesTrainer(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/user-requests/coach-pref-times/`).pipe(
      tap(_ => console.log('fetched pref times of trainer'))
    );
  }

  getAllPrefTimesTrainer(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/user-requests/all-coach-pref-times/`).pipe(
      tap(_ => console.log('fetched pref times of trainer'))
    );
  }

  setPrefTimesTrainer(data: any): Observable<any> {
    return this.http.put(`${this.baseUrl}api/user-requests/coach-pref-times/`, data).pipe(
      tap(_ => console.log('updated pref times of trainer')));
  }
}
