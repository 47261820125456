<div class="cf-main-container">
  <div class="cf-verify-container">
    <mat-card>
      <div class="cf-card-inner">
        <mat-icon color="primary">verified_user</mat-icon>
        <div class="cf-verify-title"><h1 class="mat-h1 cf-h1">Sie haben erfolgreich Ihre E-Mailadresse verifiziert</h1></div>
      </div>
      <div>
        <a href="/"><button mat-button mat-raised-button color="primary">Home</button></a>
      </div>
    </mat-card>
  </div>
</div>
