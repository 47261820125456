<div>
  <h1 class="cf-h1 mat-h1">Datenschutz&shy;erklärung Be Tennis GmbH</h1>
  <p>
    <strong>Wir nehmen Datenschutz ernst</strong><br />
  <br />
    Der Schutz Ihrer Privatsphäre bei der Verarbeitung persönlicher Daten ist für uns ein wichtiges Anliegen. Wenn Sie unsere Webseite besuchen, speichern unsere Webserver standardmäßig die IP Ihres Internet Service Provider, die Webseite, von der aus Sie uns besuchen, die Webseiten, die Sie bei uns besuchen sowie das Datum und die Dauer des Besuches. Diese Informationen sind für die technische Übertragung der Webseiten und den sicheren Serverbetrieb zwingend erforderlich. Eine personalisierte Auswertung dieser Daten erfolgt nicht.<br />
  <br />
    Ihre Daten werden von uns ausschließlich zur Bearbeitung Ihres Anliegens verwendet. Ihre Daten werden streng vertraulich behandelt. Eine Weitergabe an Dritte erfolgt nicht.
  </p>
  <p>
    Verantwortlicher:<br />
    <br />
    Be Tennis GmbH<br />
    An der Schloßbreite 11 a<br />
    93080 Pentling<br />
    Tel.: 09405 9698600<br />
    E-Mail: matthiasmischka@gmx.de
  </p>
  <p>
    <strong>Personenbezogene Daten</strong><br />
    <br />
    Personenbezogene Daten sind Daten über Ihre Person. Diese beinhalten Ihren Namen, Ihre Adresse und Ihre Email Adresse. Sie müssen auch keine personenbezogenen Daten preisgeben um unsere Internetseite besuchen zu können. In einigen Fällen benötigen wir Ihren Namen und Adresse sowie weitere Informationen um Ihnen die gewünschte Dienstleistung anbieten zu können.<br />
    <br />
    Das Gleiche gilt für den Fall, dass wir Sie auf Wunsch mit Informationsmaterial beliefern bzw. wenn wir Ihre Anfragen beantworten. In diesen Fällen werden wir Sie immer darauf hinweisen. Außerdem speichern wir nur die Daten, die Sie uns automatisch oder freiwillig übermittelt haben.<br />
    <br />
    Wenn Sie einen unserer Services nutzen, sammeln wir in der Regel nur die Daten die notwendig sind um Ihnen unseren Service bieten zu können. Möglicherweise fragen wir Sie nach weiteren Informationen, die aber freiwilliger Natur sind. Wann immer wir personenbezogene Daten verarbeiten, tun wir dies um Ihnen unseren Service anbieten zu können oder um unsere kommerziellen Ziele zu verfolgen.
  </p>
  <p>
    <strong>Automatisch gespeicherte Daten</strong><br />
    <br />
    <strong>Server-Log-Dateien</strong><br />
    <br />
    Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
  </p>
  <ul>
    <li>Datum und Uhrzeit der Anforderung</li>
    <li>Name der angeforderten Datei</li>
    <li>Seite, von der aus die Datei angefordert wurde</li>
    <li>Zugriffsstatus (Datei übertragen, Datei nicht gefunden, etc.)</li>
    <li>verwendeter Webbrowser und verwendetes Betriebssystem</li>
    <li>vollständige IP-Adresse des anfordernden Rechners</li>
    <li>übertragene Datenmenge</li>
  </ul>
  <p>
    Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilität und Funktionalität unserer Website.<br />
    Aus Gründen der technischen Sicherheit, insbesondere zur Abwehr von Angriffsversuchen auf unseren Webserver, werden diese Daten von uns kurzzeitig gespeichert. Ein Rückschluss auf einzelne Personen ist uns anhand dieser Daten nicht möglich. Nach spätestens sieben Tagen werden die Daten durch Verkürzung der IP-Adresse auf Domain-Ebene anonymisiert, so dass es nicht mehr möglich ist, einen Bezug zum einzelnen Nutzer herzustellen. In anonymisierter Form werden die Daten daneben zu statistischen Zwecken verarbeitet; ein Abgleich mit anderen Datenbeständen oder eine Weitergabe an Dritte, auch in Auszügen, findet nicht statt. Lediglich im Rahmen unserer Serverstatistik, die wir alle zwei Jahre in unserem Tätigkeitsbericht veröffentlichen, findet eine Darstellung der Anzahl der Seitenaufrufe statt.
  </p>
  <p>
    <strong>Cookies</strong><br />
    <br />
    Wenn Sie unsere Internetseiten besuchen speichern wir möglicherweise Informationen auf Ihrem Computer in Form von Cookies. Viele Cookies enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist eine eindeutige Kennung des Cookies. Sie besteht aus einer Zeichenfolge, durch welche Internetseiten und Server dem konkreten Internetbrowser zugeordnet werden können, in dem das Cookie gespeichert wurde. Dies ermöglicht es den besuchten Internetseiten und Servern, den individuellen Browser der betroffenen Person von anderen Internetbrowsern, die andere Cookies enthalten, zu unterscheiden. Ein bestimmter Internetbrowser kann über die eindeutige Cookie-ID wiedererkannt und identifiziert werden.<br />
    <br />
    Durch den Einsatz von Session-Cookies kann der Verantwortliche den Nutzern dieser Internetseite einen nutzerfreundlichen Service bereitstellen, der ohne die Setzung von Cookies nicht möglich wäre. Ohne Einwilligung verwenden wir lediglich technisch notwendige Cookies auf der Rechtsgrundlage des berechtigten Interesses gemäß Art. 6 Abs. 1 lit. f DSGVO.
  </p>
  <p>
    <strong>Welche Daten werden verarbeitet und aus welchen Quellen stammen diese Daten?</strong><br />
    <br />
    Wir verarbeiten die Daten, die wir im Rahmen der Vertragsanbahnung bzw. -abwicklung, anhand von Einwilligungen oder im Rahmen Ihrer Bewerbung bei uns bzw. im Rahmen Ihrer Mitarbeiterschaft bei uns von Ihnen erhalten haben.<br />
    <br />
    <strong>Zu den personenbezogenen Daten zählen:</strong><br />
    <br />
    <strong>Trainingsteilnehmer*Innen Erwachsen:</strong>Name, Vorname, Kontaktdaten (Adresse, E-Mail, Telefonnummer), Geburtsdatum, Spielstärke, Geschlecht, Nationalität<br />
    <br />
    <strong>Trainingsteilnehmer*Innen Kinder:</strong> Name des Kindes, Spielstärke, Geschlecht, Nationalität und Geburtsdatum<br />
    <br />
    <strong>Erziehungsberechtigte:</strong> Name, Vorname bzw. gesetzliche Bevollmächtigung, Kontaktdaten (Adresse, E-Mail, Telefonnummer)
  </p>
  <p>
    <strong>Für welche Zwecke und auf welcher Rechtsgrundlage werden die Daten verarbeitet?</strong><br />
    <br />
    Wir verarbeiten Ihre Daten im Einklang mit den Bestimmungen der Datenschutz-Grundverordnung (DS-GVO) und dem Bundesdatenschutzgesetz 2018 in der jeweils geltenden Fassung:
  </p>
  <ul>
    <li>
      <strong>zur Erfüllung von (vor-)vertraglichen Pflichten (Art. 6 Abs. 1 lit.b DS-GVO):</strong><br />
      Die Verarbeitung Ihrer Daten erfolgt für die Vertragsabwicklung in diesem Fall die Onlinebuchung von Trainingsterminen. Für Abrechnungszwecke sind die Kontaktdaten notwendig. Zur Kommunikation von Trainingsterminen wird auf die gängigen Kommunikationswege zurückgegriffen (E-Mail, Telefonnummer). Bei der Anmeldung von Kindern sind die Kontaktdaten der Eltern/Erziehungsberechtigten notwendig.
      Zur genauen Einschätzung der Spielstärke und zur Zuteilung in entsprechende Gruppen sind Geschlecht und Spielstärke notwendig. Sofern der Unterricht in einer Fremdsprache erfolgen soll, benötigen wir die Infos zur Nationalität, damit ein entsprechender Trainer zugeteilt werden kann.
    </li>
    <li>
      <strong>zur Wahrung berechtigter Interessen (Art. 6 Abs. 1 lit. f DS-GVO):</strong><br />
      Aufgrund einer Interessenabwägung kann eine Datenverarbeitung über die eigentliche Erfüllung des Vertrags hinaus zur Wahrung berechtigter Interessen von uns oder Dritten erfolgen. Eine Datenverarbeitung zur Wahrung berechtigter Interessen erfolgt beispielsweise in folgenden Fällen: Verschiebung von Trainingsterminen
    </li>
  </ul>
  <p>
    <strong>Verarbeitung personenbezogener Daten zu Werbezwecken</strong><br />
    <br />
    Ihre angegebenen personenbezogenen Daten verwenden wir nicht zu Werbezwecken. Die Daten werden lediglich zur Abwicklung des Trainings- und Buchungsbetriebes verwendet. Eine unberechtigte Weitergabe an Dritte erfolgt nicht.
  </p>
  <p>
    <strong>Wer erhält meine Daten?</strong><br />
    <br />
    Für das Buchungsportal haben wir einen externen Dienstleister beauftragt. Mit diesem ist ein Vertrag zur Auftragsverarbeitung nach Art. 28 DSGVO abgeschlossen, der alle notwendigen und vorgeschrieben Vorgaben es Art. 28 DSGVO enthält und erfüllt.<br />
    Der Auftragsverarbeiter ist vertraglich dazu verpflichtet, Ihre Daten vertraulich zu behandeln und nur im Rahmen der Leistungserbringung zu verarbeiten. Der von uns beauftragte Auftragsverarbeiter erhält Ihre Daten, sofern dieser die Daten zur Erfüllung der jeweiligen Leistung benötigt.<br />
    <br />
    Bei Vorliegen einer gesetzlichen Verpflichtung sowie im Rahmen der Rechtsverfolgung können Behörden und Gerichte sowie externe Auditoren Empfänger Ihrer Daten sein.<br />
    Darüber hinaus können zum Zweck der Vertragsanbahnung und -erfüllung Versicherungen, Banken, Auskunfteien und Dienstleister Empfänger Ihrer Daten sein.
  </p>
  <p>
    <strong>Wie lange werden meine Daten gespeichert?</strong><br />
    <br />
    Wir verarbeiten Ihre Daten bis zur Beendigung der Geschäftsbeziehung oder bis zum Ablauf der geltenden gesetzlichen Aufbewahrungsfristen (etwa aus dem Handelsgesetzbuch, der Abgabenordnung, oder Arbeitszeitgesetz); darüber hinaus bis zur Beendigung von allfälligen Rechtsstreitigkeiten, bei denen die Daten als Beweis benötigt werden.
  </p>
  <p>
    <strong>Werden personenbezogene Daten an ein Drittland übermittelt?</strong><br />
    <br />
    Grundsätzlich werden von uns keine Daten an ein Drittland übermittelt. Eine Übermittlung findet im Einzelfall nur auf Grundlage eines Angemessenheitsbeschlusses der Europäischen Kommission, Standardvertragsklauseln, geeigneter Garantien oder Ihrer ausdrücklichen Zustimmung statt.
  </p>
  <p>
    <strong>Welche Datenschutzrechte habe ich?</strong><br />
    <br />
    Sie haben jederzeit ein Recht auf Auskunft, Berichtigung, Löschung oder Einschränkung der Verarbeitung Ihrer gespeicherten Daten, ein Widerspruchsrecht gegen die Verarbeitung sowie ein Recht auf Datenübertragbarkeit und auf Beschwerde gemäß den Voraussetzungen des Datenschutzrechts.<br />
    <br />
    <strong>Recht auf Auskunft:</strong><br />
    Sie können von uns eine Auskunft verlangen, ob und in welchem Ausmaß wir Ihre Daten verarbeiten.<br />
    <br />
    <strong>Recht auf Berichtigung:</strong><br />
    Verarbeiten wir Ihre Daten, die unvollständig oder unrichtig sind, so können Sie jederzeit deren Berichtigung bzw. deren Vervollständigung von uns verlangen.<br />
    <br />
    <strong>Recht auf Löschung:</strong><br />
    Sie können von uns die Löschung Ihrer Daten verlangen, sofern wir diese unrechtmäßig verarbeiten oder die Verarbeitung unverhältnismäßig in Ihre berechtigten Schutzinteressen eingreift. Bitte beachten Sie, dass es Gründe geben kann, die einer sofortigen Löschung entgegenstehen, z.B. im Fall von gesetzlich geregelten Aufbewahrungspflichten.<br />
    Unabhängig von der Wahrnehmung Ihres Rechts auf Löschung, werden wir Ihre Daten umgehend und vollständig löschen, soweit keine diesbezügliche rechtsgeschäftliche oder gesetzliche Aufbewahrungspflicht entgegensteht.
  </p>
  <div>
    <strong>Recht auf Einschränkung der Verarbeitung:</strong><br />
    Sie können von uns die Einschränkung der Verarbeitung Ihrer Daten verlangen, wenn<br />
    <ul>
      <li>Sie die Richtigkeit der Daten bestreiten, und zwar für eine Dauer, die es uns ermöglicht, die Richtigkeit der Daten zu überprüfen.</li>
      <li>die Verarbeitung der Daten unrechtmäßig ist, Sie aber eine Löschung ablehnen und stattdessen eine Einschränkung der Datennutzung verlangen,</li>
      <li>wir die Daten für den vorgesehenen Zweck nicht mehr benötigen, Sie diese Daten aber noch zur Geltendmachung oder Verteidigung von Rechtsansprüchen brauchen, oder</li>
      <li>Sie Widerspruch gegen die Verarbeitung der Daten eingelegt haben.</li>
    </ul>
  </div>
  <div>
    <strong>Recht auf Datenübertragbarkeit:</strong><br />
    Sie können von uns verlangen, dass wir Ihnen Ihre Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zur Verfügung stellen und dass Sie diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns übermitteln können, sofern<br />
    <ul>
      <li>wir diese Daten aufgrund einer von Ihnen erteilten und widerrufbaren Zustimmung oder zur Erfüllung eines Vertrages zwischen uns verarbeiten, und</li>
      <li>diese Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
    </ul>
    Bei technischer Machbarkeit können Sie von uns eine direkte Übermittlung Ihrer Daten an einen anderen Verantwortlichen verlangen.
  </div>
  <p>
    <strong>Widerspruchsrecht:</strong><br />
    Verarbeiten wir Ihre Daten aus berechtigtem Interesse, so können Sie gegen diese Datenverarbeitung jederzeit Widerspruch einlegen; dies würde auch für ein auf diese Bestimmungen gestütztes Profiling gelten. Wir verarbeiten dann Ihre Daten nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. Der Verarbeitung Ihrer Daten zum Zweck der Direktwerbung können Sie jederzeit ohne Angabe von Gründen widersprechen.
  </p>
  <p>
    <strong>Beschwerderecht:</strong><br />
    Sind Sie der Meinung, dass wir bei der Verarbeitung Ihrer Daten gegen deutsches oder europäisches Datenschutzrecht verstoßen, so bitten wir Sie, mit uns Kontakt aufzunehmen, um Fragen aufklären zu können. Sie haben selbstverständlich auch das Recht, sich an die für Sie zuständige Aufsichtsbehörde, das jeweilige Landesamt für Datenschutzaufsicht, zu wenden.<br />
    Sofern Sie eines der genannten Rechte uns gegenüber geltend machen wollen, so wenden Sie sich bitte an unseren Datenschutzbeauftragten. Im Zweifel können wir zusätzliche Informationen zur Bestätigung Ihrer Identität anfordern.
  </p>
  <p>
    <strong>Bin ich zur Bereitstellung von Daten verpflichtet?</strong><br />
    <br />
    Die Verarbeitung Ihrer Daten ist zum Abschluss bzw. zur Erfüllung Ihres mit uns eingegangenen Vertrages erforderlich. Wenn Sie uns diese Daten nicht zur Verfügung stellen, werden wir den Abschluss des Vertrags in der Regel ablehnen müssen oder einen bestehenden Vertrag nicht mehr durchführen können und folglich beenden müssen. Sie sind jedoch nicht verpflichtet, hinsichtlich für die Vertragserfüllung nicht relevanter bzw. gesetzlich nicht erforderlicher Daten eine Einwilligung zur Datenverarbeitung zu erteilen.
  </p>
  <p>
    <strong>Sicherheit</strong><br />
    <br />
    Wir haben technische und administrative Sicherheitsvorkehrungen getroffen um Ihre personenbezogenen Daten gegen Verlust, Zerstörung, Manipulation und unautorisierten Zugriff zu schützen. All unsere Mitarbeiter sowie für uns tätige Dienstleister sind auf die gültigen Datenschutzgesetze verpflichtet.<br />
    <br />
    Wann immer wir personenbezogene Daten sammeln und verarbeiten werden diese verschlüsselt bevor sie übertragen werden. Das heißt, dass Ihre Daten nicht von Dritten missbraucht werden können. Unsere Sicherheitsvorkehrungen unterliegen dabei einem ständigen Verbesserungsprozess und unsere Datenschutzerklärungen werden ständig überarbeitet. Bitte stellen Sie sicher, dass Ihnen die aktuellste Version vorliegt.
  </p>
  <p>
    <strong>Änderungen dieser Datenschutzerklärung</strong><br />
    <br />
    Wir behalten uns das Recht vor, unsere Datenschutzerklärungen zu ändern falls dies aufgrund neuer Technologien notwendig sein sollte. Bitte stellen Sie sicher, dass Ihnen die aktuellste Version vorliegt. Werden an dieser Datenschutzerklärung grundlegende Änderungen vorgenommen, geben wir diese auf unserer Website bekannt.<br />
    <br />
    Alle Interessenten und Besucher unserer Internetseite erreichen uns in Datenschutzfragen unter:<br />
    <br />
    Herrn Nico Becker<br />
    Projekt 29 GmbH & Co. KG<br />
    Ostengasse 14<br />
    93047 Regensburg<br />
    <br />
    Tel.:  0941 2986930<br />
    Fax:  0941 29869316<br />
    E-Mail: n.becker@projekt29.de<br />
    Internet: www.projekt29.de
  </p>
</div>
