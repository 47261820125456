import {Player} from './player';

export class Person {
  id?: number;
  sex: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  nationality: string;
  street: string;
  country: string;
  city: string;
  zip: number;
  phone_mobile: number;
  phone_private: string;
  message: string;
  message_change_date?: Date;
  message_read?: boolean;
  managed_by: number;
  photo?: any;
  smallPhoto?: any;
  player?: Player;
  rtk?: boolean;
}
