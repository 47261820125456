<form *ngIf="formLoaded" [formGroup]="stepOneForm">
  <p>Registrieren Sie hier einen neuen Account. Weitere Spieler (z.B Familienmitglieder) können Sie dann über diesen Account anlegen.</p>
  <div>
    <div class="decoy">
      <mat-form-field class="full-width padding">
        <mat-label>E-Mail</mat-label>
        <input type="email" matInput placeholder="Gültige Email Adresse eingeben" formControlName="decoyEmail">
      </mat-form-field>
    </div>
    <mat-form-field class="full-width padding">
      <mat-label>E-Mail *</mat-label>
      <input matInput placeholder="Gültige Email Adresse eingeben" formControlName="email">
      <mat-error>Keine gültige Email Adresse</mat-error>
    </mat-form-field>
    <mat-form-field class="full-width padding">
      <mat-label>Passwort *</mat-label>
      <input type="password" matInput formControlName="password">
      <mat-error>Mindestlänge von 8 Zeichen</mat-error>
    </mat-form-field>
    <mat-form-field class="full-width padding">
      <mat-label>Passwort bestätigen *</mat-label>
      <input type="password" matInput formControlName="secondPassword">
      <mat-error *ngIf="secondPassword != f1.password.value">Passwörter stimmen nicht überein</mat-error>
    </mat-form-field>
    <app-shared-image-cropper (SubmitData)="savePhotos($event)"></app-shared-image-cropper>
    <mat-form-field class="full-width padding">
      <mat-label>Vorname *</mat-label>
      <input matInput formControlName="firstName">
    </mat-form-field>
    <mat-form-field class="full-width padding">
      <mat-label>Nachname *</mat-label>
      <input matInput formControlName="lastName">
    </mat-form-field>
    <mat-form-field class="full-width padding">
      <mat-label>Geburtsdatum *</mat-label>
      <input  placeholder="Format: DD.MM.YYYY" matInput [max]="maxDate" [matDatepicker]="picker" formControlName="bday">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker [startAt]="startDate"></mat-datepicker>
      <mat-error>Sie haben entweder das Geburtsdatum falsch formatiert oder sind noch nicht volljährig.</mat-error>
    </mat-form-field>
    <mat-form-field class="full-width padding">
      <mat-label>m/w *</mat-label>
      <mat-select formControlName="gender">
        <mat-option *ngFor="let gen of genders" [value]="gen.value" >
          {{gen.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width padding">
      <mat-label>Nationalität *</mat-label>
      <mat-select formControlName="nationality">
        <mat-option *ngFor="let nationality of nationalities" [value]="nationality.value" >
          {{nationality.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="f1.nationality.value === 'Andere'" class="full-width padding">
      <mat-label>Nationalität</mat-label>
      <input matInput formControlName="differentNationality">
    </mat-form-field>
    <mat-form-field class="full-width padding">
      <mat-label>RTK Mitglied *</mat-label>
      <mat-select formControlName="rtk">
        <mat-option [value]="true">RTK Mitglied</mat-option>
        <mat-option [value]="false"><strong>kein</strong> RTK Mitglied</mat-option>
      </mat-select>
    </mat-form-field>
    <div  class="text-center">
      <button class="padding" mat-flat-button color="primary" type="button" (click)="stepOneSubmit()" [disabled]="stepOneForm.invalid || f1.decoyEmail.value !== ''">WEITER</button>
      <button class="cancel-button padding" mat-button type="button" (click)="back()" color="warn">ABBRECHEN</button>
    </div>
  </div>
</form>
