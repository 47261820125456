
<h1>Impressum</h1>
<h2>Angaben gemäß § 5 TMG</h2>
<p>
  <strong>
    BeTennis GmbH<br />
    An der Schloßbreite 11a<br />
    93080 Pentling
  </strong>
</p>
<p>
  <strong>Telefon:</strong> 09405/9698600<br />
  <strong>E-Mail:</strong> info@betennis.net
</p>
<p>
  <strong>Geschäftsführer:</strong> Matthias Mischka, Michael Geserer
  <strong>Handelsregistereintrag:</strong> Amtsgericht Regensburg, HRB-Nr.: 14841
</p>
<h2>Online-Streitbeilegung</h2>
<p>Die Europäische Kommission stellt unter https://ec.europa.eu/consumers/odr/ eine Plattform zur Online-Streitbeilegung bereit, die Verbraucher für die Beilegung einer Streitigkeit nutzen können und auf der weitere Informationen zum Thema Streitschlichtung zu finden sind.</p>
<h2>Außergerichtliche Streitbeilegung</h2>
<p>Wir sind weder verpflichtet noch dazu bereit, im Falle einer Streitigkeit mit einem Verbraucher an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

