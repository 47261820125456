<div class="container">
  <div *ngIf="passwordForm" [formGroup]="passwordForm" class="content-width center">
    <h1 class="mat-h1 cf-h1" align="center">PASSWORT ZURÜCKSETZEN</h1>
    <div *ngIf="!reset">
      <mat-form-field class="full-width">
        <mat-label> Passwort</mat-label>
        <input type="password" matInput formControlName="password">
        <mat-error *ngIf="f.password.invalid">Passwörter haben mindestens 8 Zeichen</mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label> Passwort bestätigen</mat-label>
        <input type="password" matInput formControlName="confirm_password">
        <mat-error *ngIf="f.confirm_password.invalid">Passwörter stimmen nicht überein</mat-error>
      </mat-form-field>
      <div fxLayoutAlign="center" class="cf-action-button">
        <button class="button cf-action-button" mat-button color="primary" [disabled]="passwordForm.invalid" (click)="submit()">Bestätigen</button>
        <a href="/"><button class="button cf-action-button" mat-button color="warn">Abbrechen</button></a>
      </div>
    </div>
  </div>
  <div *ngIf="reset" class="center content-width">
    <p class="cf-text-center">Das Zurücksetzen ihres Passworts kann einige Minuten dauern, bitte haben sie etwas Geduld.</p>
    <div fxLayoutAlign="center">
      <a href="/"><button class="button" mat-raised-button (click)="back()">Zum Login</button></a>
    </div>
  </div>
</div>
