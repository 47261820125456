import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Player} from '../_classes/player';
import {BASE_URL} from '../globals';
import {UrlService} from './url.service';

@Injectable({
  providedIn: 'root'
})
export class PlayersService {

  baseUrl = BASE_URL;

  constructor(
    private http: HttpClient,
    private urlService: UrlService
  ) {
    this.baseUrl = this.urlService.getURL();
  }

  getPlayerStates(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/admin-requests/player-state/`).pipe(
      tap(_ => console.log('fetched player states'))
    );
  }
  addPlayer(player: Player) {
    return this.http.post<Player>(`${this.baseUrl}api/user-requests/players/`, player)
      .pipe(tap(() => console.log('New Player added')),
        catchError(this.handleError<any>('addPlayer')));
  }
  getPlayers(): Observable<Player[]> {
    return this.http.get<Player[]>(`${this.baseUrl}api/user-requests/players/`)
      .pipe(
        tap(_ => console.log('fetched all players'))
      );
  }
  getPlayer(id: number) {
    return this.http.get<Player>(`${this.baseUrl}api/user-requests/players/${id}/`)
      .pipe(
        tap(_ => console.log('fetched player with id ' + id))
      );
  }
  updatePlayer(player: Player): Observable<any> {
    console.log(player);
    return this.http.put(`${this.baseUrl}api/user-requests/players/${player.id}/`, player).pipe(
      tap(_ => console.log('Player updated')));
  }
  uploadDetailPhoto(data: any, id: number): Observable<any> {
    return this.http.put(`${this.baseUrl}api/user-requests/upload-photo-detail/${id}/`, data).pipe(
      tap(_ => console.log('Uploaded Photo')));
  }
  uploadSmallPhoto(data: any, id: number): Observable<any> {
    return this.http.put(`${this.baseUrl}api/user-requests/upload-photo-base/${id}/`, data).pipe(
      tap(_ => console.log('Uploaded Photo')));
  }
  getSmallPhoto(id: number) {
    return this.http.get<Player>(`${this.baseUrl}api/user-requests/upload-photo-base/${id}/`)
      .pipe(
        tap(_ => console.log('fetched player photo with id ' + id))
      );
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result
      return of(result as T);
    };
  }
}
