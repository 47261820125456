import {Component, Output, EventEmitter, OnInit, Input, ViewChild} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {AccountService} from '../_services/account.service';
import {Account} from '../_classes/account';
import {TrainingService} from '../_services/training.service';
import {PlayersService} from '../_services/players.service';
import {PhotosService} from '../_services/photos.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Output() public logout = new EventEmitter();

  @ViewChild('drawer') drawer: any;

  isTrainer: any;

  log = false;
  handheldBol = false;

  account: Account;

  photo: any;
  photos: any;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  disableSidenav() {
    this.log = true;
  }

  toggleDrawer() {
    if (this.handheldBol) {
      this.drawer.toggle();
    }
  }
  public onLogout = () => {
    this.logout.emit();
    this.drawer.toggle();
    this.disableSidenav();
  }

  constructor(private breakpointObserver: BreakpointObserver,
              private accountService: AccountService,
              private trainingService: TrainingService,
              private playerService: PlayersService,
              private photoService: PhotosService) {
    breakpointObserver.observe(Breakpoints.Handset).subscribe(
      result => {
        if (result.matches) {
          this.handheldBol = true;
        } else {
          this.handheldBol = false;
        }
      });
    this.getAccount();
  }

  getAccount() {
    this.accountService.getAccount().subscribe(
      account => this.account = account,
      () => {},
      () => {
        console.log(this.account);
        this.trainingService.checkTrainer().subscribe(
          result => this.isTrainer = result,
          () => {},
        () => {
            this.getPhoto();
            this.getInintalPhoto();
        }
        );
      }
    );
  }

  getInintalPhoto() {
    // tslint:disable-next-line:variable-name
    for (const _photo of this.photoService.photos) {
      if (_photo.id === this.account.account_details.main_person.id) {
        this.photo = _photo.photo;
      }
    }
  }

  getPhoto() {
    this.photoService.getPhotosObs().subscribe({
      next: data => {
        this.photos = data;
        console.log('PHOTOS: ');
        console.log(this.photos);
        for (const _photo of this.photos) {
          if (_photo.id === this.account.account_details.main_person.id) {
            this.photo = _photo.photo;
          }
        }
      }
    });
  }

  loadPhotosInService() {
    this.photoService.loadPhotos();
    this.photoService.loadPhotoLS();
  }

  ngOnInit() {
    this.loadPhotosInService();
  }

}
