import {AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from './_services';
import {PhotosService} from './_services/photos.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterContentChecked, AfterContentInit {
  title = 'Tac-User-Frontend';

  startRegister = false;
  startReset = false;
  startPasswordReset = false;
  isLoggedIn: any;
  isMailVerify = false;
  isPublicData = false;
  loading = true;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private photoService: PhotosService,
  ) {
    this.getStatus();
    this.checkUrl();
  }

  ngAfterContentInit(): void {
    if (this.loading === true) {
      this.loading = false;
    }
  }

  ngAfterContentChecked(): void {
    const tokenArray = this.router.url.split('/');
    if (tokenArray[tokenArray.length - 2] === 'password-reset-token') { // needs Url /password-reset-token/{token}
      const token = tokenArray[tokenArray.length - 1]; // this is the token
      this.startPasswordReset = true;
    }
  }

  checkUrl() {
    const url = window.location.href;
    // check if the url contains 'email-verification'
    if (url.indexOf('email-verification') !== -1) {
      this.isMailVerify = true;
    }
    if (url.indexOf('public-data') !== -1) {
      this.isPublicData = true;
    }
  }

  getStatus() {
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    if (this.isLoggedIn) {
      this.photoService.loadPhotoLS();
    }
    this.registerStopped();
  }
  registerStarted() {
    this.startRegister = true;
  }

  passwordResetStopped() {
    // this.startPasswordReset = false;
    this.router.navigate(['/login']);
    location.reload();
  }

  resetStarted() {
    this.startReset = true;
  }

  resetStopped() {
    this.startReset = false;
  }

  registerStopped() {
    this.startRegister = false;
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
    this.getStatus();
  }
}
