import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import {ReactiveFormsModule} from '@angular/forms';
import { AngularMaterialModule } from '../-angular-material/-angular-material.module';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import {FlexModule} from '@angular/flex-layout';


@NgModule({
    declarations: [LoginComponent, PasswordResetComponent],
    exports: [
        LoginComponent,
        PasswordResetComponent
    ],
    imports: [
        CommonModule,
        LoginRoutingModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        FlexModule,
    ]
})
export class LoginModule { }
