<div class="cf-container cf-bg-imp">
  <mat-card class="cf-imp-card">
    <app-data-privacy *ngIf="isPrivacy"></app-data-privacy>
    <app-impressum *ngIf="isImpressum"></app-impressum>
    <app-terms-and-conditions *ngIf="isTermsAndConditions"></app-terms-and-conditions>
    <div>
      <a href="/"><button mat-button mat-flat-button color="primary">Zurück</button></a>
    </div>
  </mat-card>
</div>
