<div class="container">
  <div *ngIf="emailForm" [formGroup]="emailForm" class="content-width center">
    <h3 align="center" class="mat-h2 cf-h2">Passwort zurücksetzen</h3>
    <p align="center">Geben Sie die E-Mail Adresse ihres Accounts an, um eine E-Mail mit Anweisungen zur Passwort Zurücksetzung zu erhalten.</p>
    <div *ngIf="!reset">
      <mat-form-field class="full-width">
        <mat-label> E-Mail Adresse</mat-label>
        <input matInput placeholder="Gültige Email Adresse eingeben" formControlName="email">
        <mat-error *ngIf="f.email.invalid">Gültige Email Adresse eingeben</mat-error>
      </mat-form-field>
      <button class="button" mat-raised-button color="primary" [disabled]="emailForm.invalid" (click)="submit()">Bestätigen</button>
      <button class="button" mat-button color="warn" (click)="back()">Abbrechen</button>
    </div>
  </div>
  <div *ngIf="reset" class="center content-width">
    <p class="center" align="center">Sie werden in Kürze eine E-Mail mit weiteren Anweisungen erhalten.</p>
    <div fxLayoutAlign="center">
      <button class="button" mat-raised-button (click)="back()">Zurück</button>
    </div>
  </div>
</div>
