import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, of} from 'rxjs';
import { User} from '../_classes/user';
import { MessageService } from './message.service';
import { Person } from '../_classes/person';
import {BASE_URL} from '../globals';
import {UrlService} from './url.service';

@Injectable({ providedIn: 'root'})
export class UserService {

  baseUrl = BASE_URL;

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

   httpOptions = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' })
   };

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private urlService: UrlService
  ) {
    this.baseUrl = this.urlService.getURL();
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // // returns all persons in the database
  // getPersons(): Observable<Person[]> {
  //   return this.http.get<Person[]>(this.personsUrl).pipe(
  //     tap(_ => console.log('fetched all persons'))
  //   );
  // }

  // // returns one person with a specific id
  // getPerson(id: number): Observable<Person> {
  //   const url = `${this.personsUrl}/${id}`;
  //   return this.http.get<Person>(url)
  //     .pipe(
  //       tap(_ => console.log('fetched one person'))
  //     );
  // }

  // updatePerson(person: Person): Observable<any> {
  //   return this.http.put(this.personsUrl, person, this.httpOptions).pipe(
  //     tap(_ => console.log('Person updated')),
  //     catchError(this.handleError<any>('updatePerson'))
  //   );
  // }

  // addPerson(person: Person): Observable<Person> {
  //   return this.http.post<Person>(this.personsUrl, person, this.httpOptions).pipe(
  //     tap((newPerson: Person) => console.log('added Person')),
  //     catchError(this.handleError<Person>('addPerson'))
  //   );
  // }

  // deletePerson(person: Person | number): Observable<Person> {
  //   const id = typeof person === 'number' ? person : person.id;
  //   const utl = `${this.personsUrl}/${id}`;
  //
  //   return this.http.delete<Person>(utl, this.httpOptions).pipe(
  //     tap(_ => this.messageService.add('deleted Person')),
  //     catchError(this.handleError<Person>('deletePerson'))
  //   );
  // }

  // login() {
  //   const url = `${this.personsUrl}/0`;
  //   return this.http.get<Person>(url)
  //     .pipe(map(user => {
  //       localStorage.setItem('currentUser', JSON.stringify(user));
  //       this.currentUserSubject.next(user);
  //       return user;
  //     }));
  // }
  // logout() {
  //   localStorage.removeItem('currentUser');
  //   this.currentUserSubject.next(null);
  // }
  //
  // register(user) {
  //   return this.http.post<User>(`${this.baseUrl}api/users/`, user)
  //     .pipe(tap((newUser: User) => this.messageService.add('New User created')),
  //       catchError(this.handleError<User>('register')));
  // }
  //
  // // Funktion funktioniert noch nicht! Muss noch auf Person anstatt User umgeschrieben werden, oder andere Funktion anlegen, die sich um Persons kümmert, da User eh eine eigene benötigt
  // updateUser(user: User): Observable<any> {
  //   return this.http.put(`${this.baseUrl}api/user/`, user).pipe(
  //     tap(_ => this.log('update User')),
  //     catchError(this.handleError<any>('updateUser'))
  //   );
  // }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result
      return of(result as T);
    };
  }

  private log(message: string) {
    this.messageService.add(`UserService: ${message}`);
  }
}
