<mat-toolbar color="primary">
  <h1>BeTennis</h1>
</mat-toolbar>
<div class="grid-container cf-m-t-50">
  <div>
    <button mat-mini-fab class="cancel-button" color="warn" (click)="backStepOne()">X</button>
  </div>
  <mat-vertical-stepper linear #stepper class="content-width">
    <mat-step [completed]="stepOneBol">
      <ng-template matStepLabel>Persönliche Daten</ng-template>
      <mat-error *ngIf="registerProblem">Die Registration war fehlerhaft. Entweder wurde mit dieser E-Mail bereits ein Account erstellt, oder eine Eingabe war fehlerhaft. Bitte überprüfen Sie ihre Eingaben.</mat-error>
      <app-step-one-register (SubmitStepOne)="saveStepOneData($event)" (BackStepOne)="backStepOne()"  [person]="person"></app-step-one-register>
    </mat-step>
    <mat-step [completed]="stepTwoBol">
      <ng-template matStepLabel>Kontaktdaten</ng-template>
      <app-step-two-register (SubmitStepTwo)="saveStepTwoData($event)" (BackStepTwo)="backStepTwo()" [person]="person"></app-step-two-register>
    </mat-step>
    <mat-step [completed]="stepThreeBol">
      <ng-template matStepLabel>Training</ng-template>
      <app-step-three-register (SubmitStepThree)="saveStepThreeData($event)" (BackStepThree)="backStepThree()" [person]="person"></app-step-three-register>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Fertig</ng-template>
      <h1 class="mat-h1">Danke!</h1>
      <p>Vielen Dank für die Registrierung. Ihre Daten werden nun geprüft und wir melden uns in Kürze per E-Mail.</p>
      <p>Sie werden automatisch eingeloggt, dies kann einen Moment dauern!</p>
      <div *ngIf="true" class="cf-m-t-20">
        <mat-spinner class="center" diameter="40" color="primary">
        </mat-spinner>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
