<form *ngIf="formLoaded" class="text-center" [formGroup]="stepTwoForm">
  <div>
    <mat-form-field class="full-width padding">
      <mat-label>Mobil</mat-label>
      <input matInput formControlName="mobile">
      <mat-error>Falsches Format. Beispiel-Format: +491234567890</mat-error>
    </mat-form-field>
    <mat-form-field class="full-width padding">
      <mat-label>Festnetz</mat-label>
      <input matInput formControlName="phone">
      <mat-error>Falsches Format. Beispiel-Format: +491234567890</mat-error>
    </mat-form-field>
    <mat-form-field class="full-width padding">
      <mat-label>Straße und Hausnummer</mat-label>
      <input matInput formControlName="street">
      <mat-error>Bitte eine Hausnummer angeben oder richtig formatieren</mat-error>
    </mat-form-field>
    <mat-form-field class="full-width padding">
      <mat-label>PLZ</mat-label>
      <input matInput formControlName="plz">
    </mat-form-field>
    <mat-form-field class="full-width padding">
      <mat-label>Ort</mat-label>
      <input matInput formControlName="city">
    </mat-form-field>
  </div>
</form>
