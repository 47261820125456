import { Injectable } from '@angular/core';
import {TrainingService} from "./training.service";
import {PlayersService} from "./players.service";
import {Player} from "../_classes/player";
import {tap} from "rxjs/operators";
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {BASE_URL} from '../globals';
import {AccountService} from './account.service';
import {UrlService} from './url.service';

@Injectable({
  providedIn: 'root'
})
export class PhotosService {

  private players = [];
  private personIds = [];
  public photos = [];

  public photoSubject = new Subject();

  baseUrl = BASE_URL;

  constructor(
    private trainingService: TrainingService,
    private playersService: PlayersService,
    private http: HttpClient,
    private accountService: AccountService,
    private urlService: UrlService
  ) {
    this.baseUrl = this.urlService.getURL();
  }

  loadPhotos() {
    this.playersService.getPlayers().subscribe(
      players => this.players = players,
      () => {},
      () => {
        this.extractIds();
      }
    );
  }

  loadPhotoLS() {
    this.photos = JSON.parse(localStorage.getItem('photos'));
    if (this.photos) {
      this.photoSubject.next(this.photos);
    } else {
      this.photos = [];
      this.loadPhotos();
    }
  }

  newPlayerLoadAllPhotos() {
    // if a new player is added load all photos new
    localStorage.removeItem('photos');
    this.loadPhotoLS();
  }

  getPhotosObs(): Observable<any> {
    return this.photoSubject.asObservable();
  }

  extractIds() {
    this.personIds = [];
    for (const player of this.players) {
      this.personIds.push(player.person.id);
    }
    this.accountService.getAccount().subscribe(
      (data) => {
        this.personIds.push(data.account_details.main_person.id);
        this.getPhotos();
      }
    );
  }

  getPhotos() {
    let _photo: any;
    this.photos = [];
    for (const person of this.personIds) {
      this.getSmallPhoto(person).subscribe(
        pic => _photo = pic,
        () => {},
        () => {
          this.photos.push({id: person, photo: _photo});
          if (this.photos.length === this.personIds.length) {
            this.storePhotos();
          }
        }
      );
    }
  }

  updatePhoto(playerId, newPhoto) {
    for (let i = 0; i < this.photos.length; i++) {
      if (playerId === this.photos[i].id) {
        this.photos[i].photo = newPhoto;
      }
    }
    this.storePhotos();
  }

  storePhotos() {
    localStorage.setItem('photos', JSON.stringify(this.photos));
    this.photoSubject.next(this.photos);
  }

  getSmallPhoto(id: number) {
    return this.http.get<Player>(`${this.baseUrl}api/user-requests/upload-photo-base/${id}/`)
      .pipe(
        tap(_ => {
        })
      );
  }
}
