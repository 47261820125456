<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav cf-nav-color" fixedInViewport
               [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
               [mode]="(isHandset$ | async) ? 'over' : 'side'"
               [opened]="(isHandset$ | async) === false">
    <mat-toolbar *ngIf="account" class="sidenavToolbar">
      <div class="cf-toolbar cf-toolbar-img" *ngIf="photo" mat-card-avatar style="background-image: url({{photo}});background-size: cover; margin-right: 10px;"></div>
      <span class="cf-toolbar">{{account.account_details.main_person.first_name}}</span>
    </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="/dashboard" (click)="toggleDrawer()">
        <mat-icon>home</mat-icon> <span class="nav-caption">Trainingstermine</span>
      </a>
      <a mat-list-item routerLink="/player" (click)="toggleDrawer()">
        <mat-icon>people</mat-icon><span class="nav-caption">Spieler</span>
      </a>
      <a *ngIf="isTrainer" mat-list-item routerLink="/training-plan" (click)="toggleDrawer()">
      <mat-icon>format_list_bulleted</mat-icon><span class="nav-caption">Trainer Plan</span>
      </a>
      <a style="position: absolute; left: 0; bottom: 88px; " mat-list-item routerLink="/account" (click)="toggleDrawer()">
        <mat-icon>person</mat-icon> <span class="nav-caption">Benutzerkonto</span>
      </a>
      <a style="position: absolute; bottom: 40px; left: 0;" mat-list-item (click)="onLogout()">
        <mat-icon>input</mat-icon><span class="nav-caption">Logout</span>
      </a>
      <div class="cf-itcp-pages">
        <div class="impressum"><a routerLink="/public-data/impressum">Impressum</a> · <a routerLink="/public-data/terms-and-conditions">AGB</a></div>
        <a routerLink="/public-data/privacy" class="privacy">Datenschutzerklärung</a>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="#DCDEE0" class="app-toolbar">
      <button
        class="sidenav-toggler"
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <img *ngIf="!(isHandset$ | async)" src="../../assets/img/logo/be-tennis-logo.png" style="height: 50px; display: block;
    margin: 0 auto;">
      <img *ngIf="(isHandset$ | async)" src="../../assets/img/logo/be-tennis-logo.png" style="height: 50px; display: block;
    margin: 0 auto; margin-left: -20px;">
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
