import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmailVerificationRoutingModule } from './email-verification-routing.module';
import { EmailVerificationComponent } from './email-verification.component';
import {AngularMaterialModule} from '../-angular-material/-angular-material.module';
import {FlexModule} from '@angular/flex-layout';


@NgModule({
  declarations: [EmailVerificationComponent],
  exports: [
    EmailVerificationComponent
  ],
  imports: [
    CommonModule,
    EmailVerificationRoutingModule,
    AngularMaterialModule,
    FlexModule
  ]
})
export class EmailVerificationModule { }
