import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {AccountService} from '../_services/account.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-password-reset-token',
  templateUrl: './password-reset-token.component.html',
  styleUrls: ['./password-reset-token.component.scss']
})
export class PasswordResetTokenComponent implements OnInit {

  @Output() PasswordResetStop: EventEmitter<any> = new EventEmitter<any>();
  passwordForm: FormGroup;
  reset = false;
  token;

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.initForm();
    this.route.firstChild.paramMap.subscribe(params => {
      this.token = params.get('token');
    });
  }

  get f() {return this.passwordForm.controls; }

  initForm() {
    this.passwordForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(8)], ),
      confirm_password: new FormControl('', [Validators.required, this.matchValues('password')])
    });
  }

  matchValues(matchTo: string): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
      !!control.parent.value &&
      control.value === control.parent.controls[matchTo].value
        ? null
        : {isMatching: false};
    };
  }

  back() {
    this.router.navigate(['login']);
  }

  submit() {

    this.accountService.resetPasswordWithToken(this.f.password.value, this.token).subscribe(
      () => {},
      () => {},
      () => {
        this.reset = true;
      }
    );
  }

}
