import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PublicDataRoutingModule } from './public-data-routing.module';
import { PublicDataComponent } from './public-data.component';
import { DataPrivacyComponent } from './data-privacy/data-privacy.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import {AngularMaterialModule} from '../-angular-material/-angular-material.module';


@NgModule({
    declarations: [PublicDataComponent, DataPrivacyComponent, ImpressumComponent, TermsAndConditionsComponent],
    exports: [
        PublicDataComponent
    ],
    imports: [
        CommonModule,
        PublicDataRoutingModule,
        AngularMaterialModule
    ]
})
export class PublicDataModule { }
