<form *ngIf="formLoaded" [formGroup]="stepOneForm">
  <div>
    <app-shared-image-cropper (SubmitData)="savePhotos($event)"></app-shared-image-cropper>
    <mat-form-field class="full-width padding">
      <mat-label>Vorname</mat-label>
      <input matInput formControlName="firstName">
    </mat-form-field>
    <mat-form-field class="full-width padding">
      <mat-label>Nachname</mat-label>
      <input matInput formControlName="lastName">
    </mat-form-field>
    <mat-form-field class="full-width padding">
      <mat-label>Geburtsdatum</mat-label>
      <input matInput [matDatepicker]="picker" [max]="maxDate" formControlName="bday" placeholder="Format: DD.MM.YYYY">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker startView="year" [startAt]="startDate"></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="full-width padding">
      <mat-label>m/w</mat-label>
      <mat-select formControlName="gender">
        <mat-option *ngFor="let gen of genders" [value]="gen.value" >
          {{gen.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width padding">
      <mat-label>Nationalität</mat-label>
      <mat-select formControlName="nationality">
        <mat-option *ngFor="let nationality of nationalities" [value]="nationality.value" >
          {{nationality.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="f1.nationality.value === 'Andere'" class="full-width padding">
      <mat-label>Nationalität</mat-label>
      <input matInput formControlName="differentNationality">
    </mat-form-field>
    <mat-form-field class="full-width padding">
      <mat-label>RTK Mitglied</mat-label>
      <mat-select formControlName="rtk">
        <mat-option [value]="true">RTK Mitglied</mat-option>
        <mat-option [value]="false"><strong>kein</strong> RTK Mitglied</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
