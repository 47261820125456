import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Person} from '../../_classes/person';
import {RegistrationService} from '../../_services/registration.service';
import {Player} from '../../_classes/player';
import {GroupService} from '../../_services/group.service';
import {PLAYER_SKILL} from '../../globals';

@Component({
  selector: 'app-step-three-data',
  templateUrl: './step-three-data.component.html',
  styleUrls: ['./step-three-data.component.scss']
})
export class StepThreeDataComponent implements OnInit {

  @Output() SubmitStepThree: EventEmitter<any> = new EventEmitter<any>();
  @Output() BackStepThree: EventEmitter<any> = new EventEmitter<any>();

  @Input() person: Person;
  @Input() player: Player;

  formLoaded = false;

  stepThreeForm: FormGroup;
  strength: number;
  message: string;
  data: any;

  mondayPrefTime = false;
  tuesdayPrefTime = false;
  wednesdayPrefTime = false;
  thursdayPrefTime = false;
  fridayPrefTime = false;
  saturdayPrefTime = false;
  sundayPrefTime = false;

  times: any;
  pref_times = [];

  groups = [];
  selectGroups = [];

  formThree: FormGroup;

  playerSkill;

  i: number;
  nextSeason: any;

  weekdays = [];
  monday = {day: 'Montag', times: []};
  tuesday = {day: 'Dienstag', times: []};
  wednesday = {day: 'Mittwoch', times: []};
  thursday = {day: 'Donnerstag', times: []};
  friday = {day: 'Freitag', times: []};
  saturday = {day: 'Samstag', times: []};
  sunday = {day: 'Sonntag', times: []};

  get f3() { return this.stepThreeForm.controls; }

  onSliderChange(slider: any) {
    this.strength = slider.value;
  }

  initForm() {
    console.log(this.player);
    this.formThree = this.fb.group({
      singleTrainingBol: [this.player.pref_single_trainings > 0],
      singleTrainingNum: [this.player.pref_single_trainings !== 0 ? this.player.pref_single_trainings.toString() : ''],
      groupTrainingBol: [this.player.pref_group_size > 0],
      groupTrainingGroupSize: [this.player.pref_group_size !== 0 ? this.player.pref_group_size.toString() : ''],
      groupTrainingNum: [this.player.pref_group_trainings !== 0 ? this.player.pref_group_trainings.toString() : ''],
      teamTrainingBol: [this.player.pref_team_trainings],
      selectedTeam: [this.player.pref_team !== null ? this.player.pref_team : ''],
      // @ts-ignore
      flexible_with_group_size: [this.player.flexible_with_group_size]
    });
    this.setupValidatorListeners();
  }
  constructor(
    private registrationService: RegistrationService,
    private fb: FormBuilder,
    private groupService: GroupService,
  ) { }

  ngOnInit() {
    this.playerSkill = PLAYER_SKILL;
    this.getPrefTimes();
    this.getGroups();
    this.initForm();
    this.getNextSeason();
  }

  setupValidatorListeners() {
    console.log(this.formThree.get('singleTrainingBol'));
    this.formThree.get('singleTrainingBol').valueChanges.subscribe(
      value => {
        if (value) {
          this.formThree.get('singleTrainingNum').setValidators(Validators.required);
          console.log(this.formThree.get('singleTrainingNum'));

        } else {
          this.formThree.get('singleTrainingNum').clearValidators();
          this.formThree.get('singleTrainingNum').updateValueAndValidity();
          console.log(this.formThree.get('singleTrainingNum'));
        }
      }
    );
    this.formThree.get('groupTrainingBol').valueChanges.subscribe(
      value => {
        if (value) {
          this.formThree.get('groupTrainingGroupSize').setValidators(Validators.required);
          this.formThree.get('groupTrainingNum').setValidators(Validators.required);
          console.log(this.formThree.get('groupTrainingGroupSize'));
        } else {
          this.formThree.get('groupTrainingGroupSize').clearValidators();
          this.formThree.get('groupTrainingGroupSize').updateValueAndValidity();
          this.formThree.get('groupTrainingNum').clearValidators();
          this.formThree.get('groupTrainingNum').updateValueAndValidity();
          console.log(this.formThree.get('groupTrainingGroupSize'));
        }
      }
    );
    this.formThree.get('teamTrainingBol').valueChanges.subscribe(
      value => {
        if (value) {
          this.formThree.get('selectedTeam').setValidators(Validators.required);
        } else {
          this.formThree.get('selectedTeam').clearValidators();
          this.formThree.get('selectedTeam').updateValueAndValidity();
        }
      }
    );
  }

  getNextSeason() {
    this.registrationService.getNextSeason().subscribe(
      nextSeason => this.nextSeason = nextSeason,
      () => {},
      () => {
        console.log(this.nextSeason);
      }
    );
  }

  formatSeasonString(season: string) {
    const year = season.substring(0, 4);
    const month = season.substring(5, 7);
    const date = season.substring(8, 10);

    return date + '.' + month + '.' + year;
  }

  getGroups() {
    this.groupService.getGroups().subscribe(
      groups => this.groups = groups,
      () => {},
      () => {
        console.log(this.groups);
        this.initGroupSelect();
      }
    );
  }

  initGroupSelect() {
    this.selectGroups.push({value: 'keine Angabe', viewValue: 'keine Angabe'});
    for (const group of this.groups) {
      this.selectGroups.push({value: group.id, viewValue: group.name});
    }
    if (this.player.pref_team) {
      this.formThree.get('selectedTeam').setValue(this.player.pref_team);
    } else {
      this.formThree.get('selectedTeam').setValue('keine Angabe');
    }
  }

  getPrefTimes() {
    this.registrationService.getTimes().subscribe(
      times => this.times = times,
      () => {},
      () => {
                this.pref_times = this.player.pref_times;
                this.sortTimes();
                this.initForm();
                this.formLoaded = true; }
    );
  }


  sortTimes() {
    if (this.pref_times !== null) {
    for ( this.i = 0; this.i < this.times.length; this.i++) {
      switch (this.times[this.i].day) {
        case 0:
          if (this.pref_times.includes(this.times[this.i].id)) {
            this.mondayPrefTime = true;
          }
          this.monday.times.push(this.times[this.i]);
          break;
        case 1:
          if (this.pref_times.includes(this.times[this.i].id)) {
            this.tuesdayPrefTime = true;
          }
          this.tuesday.times.push(this.times[this.i]);
          break;
        case 2:
          if (this.pref_times.includes(this.times[this.i].id)) {
            this.wednesdayPrefTime = true;
          }
          this.wednesday.times.push(this.times[this.i]);
          break;
        case 3:
          if (this.pref_times.includes(this.times[this.i].id)) {
            this.thursdayPrefTime = true;
          }
          this.thursday.times.push(this.times[this.i]);
          break;
        case 4:
          if (this.pref_times.includes(this.times[this.i].id)) {
            this.fridayPrefTime = true;
          }
          this.friday.times.push(this.times[this.i]);
          break;
        case 5:
          if (this.pref_times.includes(this.times[this.i].id)) {
            this.mondayPrefTime = true;
          }
          this.saturday.times.push(this.times[this.i]);
          break;
        case 6:
          if (this.pref_times.includes(this.times[this.i].id)) {
            this.saturdayPrefTime = true;
          }
          break;
        default:
          if (this.pref_times.includes(this.times[this.i].id)) {
            this.sundayPrefTime = true;
          }
          this.sunday.times.push(this.times[this.i]);
          break;
      }
    }
    }
    if (this.monday.times.length !== 0) {this.weekdays.push(this.monday); }
    if (this.tuesday.times.length !== 0) {this.weekdays.push(this.tuesday); }
    if (this.wednesday.times.length !== 0) {this.weekdays.push(this.wednesday); }
    if (this.thursday.times.length !== 0) {this.weekdays.push(this.thursday); }
    if (this.friday.times.length !== 0) {this.weekdays.push(this.friday); }
    if (this.saturday.times.length !== 0) {this.weekdays.push(this.saturday); }
    if (this.sunday.times.length !== 0) {this.weekdays.push(this.sunday); }
  }

  addToTimes(id: number) {
    if (!this.pref_times.includes(id)) {
      this.pref_times.push(id);
    } else {
      const index = this.pref_times.indexOf(id);
      this.pref_times.splice(index, 1);
    }
    console.log(this.pref_times);
  }
  saveData() {
    this.SubmitStepThree.emit({createPlayer: true});
  }
}
