import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { User} from '../_classes/user';
import { Person } from '../_classes/person';
import {DatePipe, Location} from '@angular/common';
import {Player} from '../_classes/player';
import {StepOneDataComponent} from './step-one-data/step-one-data.component';
import {StepTwoDataComponent} from './step-two-data/step-two-data.component';
import {StepThreeDataComponent} from './step-three-data/step-three-data.component';
import {PhotosService} from '../_services/photos.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

@Component({
  selector: 'app-shared-data',
  templateUrl: './shared-data.component.html',
  styleUrls: ['./shared-data.component.scss']
})
export class SharedDataComponent implements OnInit {
  constructor(
    private location: Location,
    private datePipe: DatePipe,
    private photosService: PhotosService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.person = new Person();

  }

  @Output() SubmitData: EventEmitter<any> = new EventEmitter<any>();
  @Output() RegisterStop: EventEmitter<any> = new EventEmitter<any>();
  @Input() player: Player;
  @Input() scrollToComment?: Boolean;


  @ViewChild(StepOneDataComponent) stepOneChild: StepOneDataComponent;
  @ViewChild(StepTwoDataComponent) stepTwoChild: StepTwoDataComponent;
  @ViewChild(StepThreeDataComponent) stepThreeChild: StepThreeDataComponent;

  @ViewChild('comment') public commentDiv: ElementRef;

  ready = false;

  photo: any;
  smallPhoto: any;

  person: Person;

  user: User;

  submitted = false;
  error: string;

  commentChanged = false;
  comment: string;

  ngOnInit(): void {
    this.person = this.player.person;
    this.ready = true;
    console.log(this.player);
    if (this.scrollToComment) {
      setTimeout(() => {
        console.log(this.commentDiv);
        this.commentDiv.nativeElement.scrollIntoView({behavior: 'smooth'});
      }, 200);
    }
    
  }

  back() {
    this.location.back();
  }

  commentSubmit(event) {
    console.log(event);
    if (event.newComment) {
      this.commentChanged = true;
      this.comment = event.comment;
    } else {
      this.commentChanged = false;
    }
  }

  submit(event, cachingData: boolean) {
    console.log(event);
    if (
      this.stepOneChild.stepOneForm && this.stepTwoChild.stepTwoForm && this.stepThreeChild.formThree &&
      this.stepOneChild.stepOneForm.valid && this.stepTwoChild.stepTwoForm.valid && this.stepThreeChild.formThree.valid) {
      console.log('valid');
      if (this.commentChanged) {
        this.snackBar.open('Vielen Dank für Ihre Rückmeldung! Wir melden uns so bald wie möglich bei Ihnen.', 'Schließen', {
          duration: 7000, 
          horizontalPosition: 'center',
          verticalPosition: 'top',});
        this.person.message = this.comment;
        this.person.message_change_date = new Date();
        this.person.message_read = false;
      }
      this.submitted = true;
      this.person.first_name = this.stepOneChild.f1.firstName.value;
      this.person.last_name = this.stepOneChild.f1.lastName.value;
      this.person.sex = this.stepOneChild.f1.gender.value;
      this.person.date_of_birth = this.datePipe.transform(this.stepOneChild.f1.bday.value, 'yyyy-MM-dd');
      this.person.phone_mobile = this.stepTwoChild.f2.mobile.value;
      this.person.phone_private = this.stepTwoChild.f2.phone.value;
      this.person.street = this.stepTwoChild.f2.street.value;
      this.person.zip = this.stepTwoChild.f2.plz.value;
      this.person.city = this.stepTwoChild.f2.city.value;
      this.person.rtk = this.stepOneChild.f1.rtk.value;

      if (this.stepOneChild.f1.nationality.value === 'Andere') {
        this.person.nationality = this.stepOneChild.f1.differentNationality.value;
        this.person.country = this.stepOneChild.f1.differentNationality.value;
      } else {
        this.person.nationality = this.stepOneChild.f1.nationality.value;
        this.person.country = this.stepOneChild.f1.nationality.value;
      }

      if (this.stepOneChild.fileChanged === true) {
        this.photo = this.stepOneChild.croppedImage;
        this.smallPhoto = this.stepOneChild.smallCroppedImage;
      } else if (this.stepOneChild.fileChanged === false) {
        this.photo = '';
        this.smallPhoto = '';
      }

      this.player.person = this.person;
      if (this.player.level_self !== null) {
        this.player.level_self = this.stepThreeChild.strength;
        this.player.pref_times = this.stepThreeChild.pref_times;

        if (this.stepThreeChild.formThree.get('singleTrainingBol').value) {
          this.player.pref_single_trainings = parseInt(this.stepThreeChild.formThree.get('singleTrainingNum').value, 10);
        } else {
          this.player.pref_single_trainings = 0;
        }

        if (this.stepThreeChild.formThree.get('groupTrainingBol').value) {
          this.player.pref_group_trainings = parseInt(this.stepThreeChild.formThree.get('groupTrainingNum').value, 10);
          this.player.pref_group_size = parseInt(this.stepThreeChild.formThree.get('groupTrainingGroupSize').value, 10);
        } else {
          this.player.pref_group_trainings = 0;
          this.player.pref_group_size = 0;
        }

        if (this.stepThreeChild.formThree.get('teamTrainingBol').value && typeof this.stepThreeChild.formThree.get('selectedTeam').value !== 'string') {
          this.player.pref_team = this.stepThreeChild.formThree.get('selectedTeam').value;
        } else {this.player.pref_team = undefined; }
        this.player.pref_team_trainings = this.stepThreeChild.formThree.get('teamTrainingBol').value;
        // @ts-ignore
        this.player.flexible_with_group_size = this.stepThreeChild.formThree.get('flexible_with_group_size').value;
      }
      this.SubmitData.emit({player: this.player, smallPhoto: this.smallPhoto, photo: this.photo, cachingData});
      this.photosService.loadPhotos();
      if (event.createPlayer) {
        this.router.navigate(['/account/create-player']);
      }
    } else {
      this.stepThreeChild.formThree.get('singleTrainingNum').markAsTouched();
      this.stepThreeChild.formThree.get('groupTrainingGroupSize').markAsTouched();
      this.stepThreeChild.formThree.get('groupTrainingNum').markAsTouched();
      this.stepThreeChild.formThree.get('selectedTeam').markAsTouched();
      this.stepTwoChild.f2.street.markAsTouched();
      console.log('invalid');
      console.log(this.stepOneChild.stepOneForm);
      console.log(this.stepTwoChild.stepTwoForm);
      console.log(this.stepThreeChild.formThree);
      this.snackBar.open('Bitte füllen Sie alle rot markierten Felder aus.', 'Schließen', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }


  }
}
