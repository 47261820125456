import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Person} from '../../_classes/person';
import {UtilsService} from "../../_services/utils.service";

@Component({
  selector: 'app-step-two-register',
  templateUrl: './step-two-register.component.html',
  styleUrls: ['./step-two-register.component.scss']
})
export class StepTwoRegisterComponent implements OnInit {

  @Output() SubmitStepTwo: EventEmitter<any> = new EventEmitter<any>();
  @Output() BackStepTwo: EventEmitter<any> = new EventEmitter<any>();

  @Input() person: Person;

  stepTwoForm: FormGroup;
  data: any;

  formLoaded = false;

  get f2() { return this.stepTwoForm.controls; }

  stepTwoSubmit() {
    let combinedMobile = this.f2.mobile.value.toString();
    while (combinedMobile.charAt(0) === '0') {
      combinedMobile = combinedMobile.substring(1);
    }
    combinedMobile = this.f2.mobilePrefix.value + combinedMobile;

    let combinedPrivate = this.f2.phone.value;
    if (combinedPrivate) {
      combinedPrivate = combinedPrivate.toString();
      while (combinedPrivate.charAt(0) === '0') {
        combinedPrivate = combinedPrivate.substring(1);
      }
      combinedPrivate = this.stepTwoForm.value.privatePrefix + combinedPrivate;
    }

    this.data = {
      mobile: combinedMobile,
      phone: combinedPrivate,
      street: this.f2.street.value,
      plz: this.f2.plz.value,
      city: this.f2.city.value,
    };
    this.SubmitStepTwo.emit(this.data);
  }
  backStepTwo() {
    this.BackStepTwo.emit();
  }

  initForm() {
    this.stepTwoForm = new FormGroup({
      mobilePrefix: new FormControl('+49', [Validators.required, Validators.maxLength(3)]),
      mobile: new FormControl(this.person.phone_mobile, [Validators.required, Validators.maxLength(11), Validators.pattern('^[0-9]*$')]),
      privatePrefix: new FormControl('+49', [Validators.maxLength(3)]),
      phone: new FormControl(this.person.phone_private, [Validators.maxLength(14), Validators.pattern('^[0-9]*$')]),
      street: new FormControl(this.person.street, [Validators.required, this.utilsService.checkForValidStreet]),
      plz: new FormControl(this.person.zip, [Validators.required, Validators.maxLength(5), Validators.pattern('^[0-9]*$')]),
      city: new FormControl(this.person.city, [Validators.required])
    }, );
  }
  constructor(
    private utilsService: UtilsService,
  ) { }

  ngOnInit() {
    this.initForm();
    this.formLoaded = true;
  }

}
