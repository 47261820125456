import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from './_helpers';


const routes: Routes = [
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule), canActivate: [AuthGuard] },
  { path: 'player', loadChildren: () => import('./player/player.module').then(m => m.PlayerModule), canActivate: [AuthGuard] },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'training-plan', loadChildren: () => import('./training-plan/training-plan.module').then(m => m.TrainingPlanModule), canActivate: [AuthGuard] },
  { path: 'password-reset-token/:token', loadChildren: () => import('./password-reset-token/password-reset-token.module').then(m => m.PasswordResetTokenModule) },
  { path: 'email-verification', loadChildren: () => import('./email-verification/email-verification.module').then(m => m.EmailVerificationModule) },
  { path: 'shared-image-cropper', loadChildren: () => import('./shared-image-cropper/shared-image-cropper.module').then(m => m.SharedImageCropperModule) },
  { path: 'public-data', loadChildren: () => import('./public-data/public-data.module').then(m => m.PublicDataModule) },
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full'}
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
