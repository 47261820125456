import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RegisterRoutingModule } from './register-routing.module';
import { RegisterComponent } from './register.component';
import {SharedDataModule} from '../shared-data/shared-data.module';
import { StepOneRegisterComponent } from './step-one-register/step-one-register.component';
import { StepTwoRegisterComponent } from './step-two-register/step-two-register.component';
import { StepThreeRegisterComponent } from './step-three-register/step-three-register.component';
import { AngularMaterialModule } from '../-angular-material/-angular-material.module';
import {ReactiveFormsModule} from '@angular/forms';
import {FlexModule} from '@angular/flex-layout';
import { SharedImageCropperModule } from '../shared-image-cropper/shared-image-cropper.module';


@NgModule({
  declarations: [RegisterComponent, StepOneRegisterComponent, StepTwoRegisterComponent, StepThreeRegisterComponent],
  exports: [
    RegisterComponent
  ],
    imports: [
        CommonModule,
        RegisterRoutingModule,
        SharedDataModule,
        AngularMaterialModule,
        ReactiveFormsModule,
        FlexModule,
        SharedImageCropperModule,
    ]
})
export class RegisterModule { }
