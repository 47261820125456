<div *ngIf="player && ready" class="grid-container">
  <button mat-mini-fab class="cancelButton" color="warn" (click)="back()">X</button>
  <div fxLayout="column" fxLayoutGap="10px">
    <div>
      <mat-card>
        <mat-card-title>
          <h3 class="mat-h3 cf-h3">Persönliche Daten</h3>
        </mat-card-title>
        <mat-card-content>
        <app-step-one-data [person]="person"></app-step-one-data>
        </mat-card-content>
      </mat-card>
    </div>
    <div>
      <mat-card>
        <mat-card-title>
          <h3 class="mat-h3 cf-h3">Kontaktdaten</h3>
        </mat-card-title>
        <app-step-two-data [person]="person"></app-step-two-data>
      </mat-card>
    </div>
    <div>
      <mat-card>
        <mat-card-title>
          <h3 class="mat-h3 cf-h3">Spielerdaten</h3>
        </mat-card-title>
        <app-step-three-data [person]="person" [player]="player" (SubmitStepThree)="submit($event, true)"></app-step-three-data>
      </mat-card>
    </div>
    <div>
      <mat-card>
        <mat-card-title>
          <h3 class="mat-h3 cf-h3">Persönliche Nachricht</h3>
        </mat-card-title>
        <div #comment>
          <app-comment *ngIf="player.level_self !== null" [commentInputBol]="scrollToComment" [comment]="person.message" (newCommentSubmit)="commentSubmit($event)"></app-comment>
        </div>
        <mat-card-actions class="cf-m-t-20">
          <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
            <button mat-button (click)="back()" color="warn">Abbrechen</button>
            <button mat-button mat-raised-button
                    (click)="submit($event, false)"
                    color="primary">
              Speichern
            </button>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
