import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PasswordResetTokenRoutingModule } from './password-reset-token-routing.module';
import { PasswordResetTokenComponent } from './password-reset-token.component';
import {ReactiveFormsModule} from '@angular/forms';
import { AngularMaterialModule } from '../-angular-material/-angular-material.module';
import {FlexModule} from '@angular/flex-layout';


@NgModule({
    declarations: [PasswordResetTokenComponent],
    exports: [
        PasswordResetTokenComponent
    ],
  imports: [
    CommonModule,
    PasswordResetTokenRoutingModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FlexModule
  ]
})
export class PasswordResetTokenModule { }
