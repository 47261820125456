import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Person} from '../_classes/person';
import { Router} from '@angular/router';
import {RegistrationService} from '../_services/registration.service';
import {DatePipe} from '@angular/common';
import {AuthenticationService} from '../_services';
import {PlayersService} from '../_services/players.service';
import {PhotosService} from "../_services/photos.service";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  @Output() RegisterStop: EventEmitter<any> = new EventEmitter<any>();
  @Output() SuccessfullRegister: EventEmitter<any> = new EventEmitter<any>();

  person: Person = new Person();

  data: any;
  mainPlayer: any;

  stepOneData;
  stepTwoData;
  stepThreeData;

  stepOneBol = false;
  stepTwoBol = false;
  stepThreeBol = false;

  registerProblem = false;

  @ViewChild('stepper') stepper: any;

  constructor(
    private registrationService: RegistrationService,
    private router: Router,
    private datePipe: DatePipe,
    private authenticationService: AuthenticationService,
    private playersService: PlayersService,
    private photosService: PhotosService,
  ) {
  }



  saveStepOneData(data: any) {
    this.stepOneData = data;
    this.stepOneBol = true;
    setTimeout(() => {
      this.stepper.next();
    }, 1);

  }
  saveStepTwoData(data: any) {
    this.stepTwoData = data;
    this.stepTwoBol = true;
    setTimeout(() => {
      this.stepper.next();
    }, 1);
  }

  backStepOne() {
    this.RegisterStop.emit();
  }

  backStepTwo() {
    this.stepOneBol = false;
    setTimeout(() => {
      this.stepper.previous();
    }, 1);
  }
  backStepThree() {
    this.stepTwoBol = false;
    setTimeout(() => {
      this.stepper.previous();
    }, 1);
  }

  saveStepThreeData(data: any) {
    this.stepThreeBol = true;
    this.stepThreeData = data;
    setTimeout(() => {
      this.stepper.next();
    }, 100);
    this.onSubmit();
  }

  ngOnInit() {
    this.registrationService.onBadPassword(this.onBadPassword.bind(this));
  }

  onBadPassword() {
    setTimeout(() => {
      this.stepper.previous();
      this.stepThreeBol = false;
      setTimeout(() => {
        this.stepper.previous();
        setTimeout(() => {
          this.stepper.previous();
          this.stepOneBol = false;
        }, 200);
      }, 200);
    }, 200);
    this.registerProblem = true;
  }

  onSubmit() {
    let states;
    let readyStateId;
    this.playersService.getPlayerStates().subscribe(
      states_ => states = states_,
      () => {},
      () => {
        for (const state_ of states) {
          if (state_.state_id === 1) {
            readyStateId = state_.id;
          }
        }
        this.data = {
          account_details: {
            main_person: {
              sex: this.stepOneData.gender,
              first_name: this.stepOneData.firstName,
              last_name: this.stepOneData.lastName,
              date_of_birth: this.datePipe.transform(this.stepOneData.bday, 'yyyy-MM-dd'),
              nationality: this.stepOneData.nationality,
              street: this.stepTwoData.street,
              country: this.stepOneData.nationality,
              city: this.stepTwoData.city,
              zip: this.stepTwoData.plz,
              phone_mobile: this.stepTwoData.mobile,
              phone_private: this.stepTwoData.phone,
              message: this.stepThreeData.message === '' ? null : this.stepThreeData.message,
              rtk: this.stepOneData.rtk
            },
          }
        };
        if (this.data.account_details.main_person.phone_private === null || this.data.account_details.main_person.phone_private === '') {
          this.data.account_details.main_person.phone_private = '-';
        }
        this.mainPlayer = {
          state: readyStateId,
          level_self: this.stepThreeData.level_self,
          pref_times: this.stepThreeData.pref_times,
          pref_team: this.stepThreeData.chosenTeam,
          pref_single_trainings: this.stepThreeData.privateTraining,
          pref_group_trainings: this.stepThreeData.groupTraining,
          pref_group_size: this.stepThreeData.groupTrainingPlayers,
          pref_team_trainings: this.stepThreeData.teamTraining,
          start_directly: this.stepThreeData.start_directly,
          flexible_with_group_size: this.stepThreeData.flexible_with_group_size
        };

        console.log(this.data);

        this.registrationService.registerAccount(this.stepOneData.email, this.stepOneData.password).subscribe(
          () => {},
          () => {},
          () => {
            this.stepThreeBol = true;
            this.registrationService.addPerson(this.data).subscribe(
              (data) => {
                if (this.stepOneData.photoChanged) {
                  this.photosService.updatePhoto(data.account_details.main_person.id, this.stepOneData.photo);
                  this.playersService.uploadDetailPhoto({img: this.stepOneData.photo}, data.account_details.main_person.id).subscribe();
                  this.playersService.uploadSmallPhoto({img: this.stepOneData.smallPhoto}, data.account_details.main_person.id).subscribe();
                }
              },
              () => {},
              () => {
                if (this.stepThreeData.state !== false) {
                  this.registrationService.createMainPlayer(this.mainPlayer).subscribe(
                    () => {},
                    () => {},
                    () => {
                      this.authenticationService.login({
                        username: this.stepOneData.email,
                        email: this.stepOneData.email,
                        password: this.stepOneData.password
                      });
                      this.SuccessfullRegister.emit();
                    }
                  );
                } else {
                  this.authenticationService.login({
                    username: this.stepOneData.email,
                    email: this.stepOneData.email,
                    password: this.stepOneData.password
                  });
                  this.SuccessfullRegister.emit();
                }});
          });
      }
    );
  }
}
