import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { first } from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../_services';
import {PhotosService} from '../_services/photos.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  email: string;
  password: string;
  submitted = false;
  returnUrl: string;
  error: string;
  success: string;
  wrongCredentials = false;

  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private photosService: PhotosService,
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.isLoggedIn()) {
      this.router.navigate(['/']);
    }
  }

  @Output() ResetStart: EventEmitter<any> = new EventEmitter<any>();
  @Output() RegisterStart: EventEmitter<any> = new EventEmitter<any>();
  @Output() SuccessfullLogin: EventEmitter<any> = new EventEmitter<any>();

  startRegister() {
    this.RegisterStart.emit();
  }
  startReset() {
    this.ResetStart.emit();
  }
  ngOnInit() {

    this.authenticationService.onBadLogin(this.onBadLogin.bind(this));
    this.loginForm = new FormGroup({
      email: new FormControl(this.email, [Validators.required, Validators.email]),
      password: new FormControl(this.password, [Validators.required])
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';

    // show success message on registration
    if (this.route.snapshot.queryParams.registered) {
      this.success = 'Registration successful';
    }
  }

  get f() {return this.loginForm.controls; }

  onBadLogin() {
    this.loading = false;
    this.wrongCredentials = true;
  }
  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.error = null;
    this.success = null;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;


    this.authenticationService.login( { email: this.f.email.value, username: this.f.email.value, password: this.f.password.value})
      .pipe(first())
      .subscribe(
        success => {
          if (success) {
            console.log('success');
            this.SuccessfullLogin.emit();
            if (this.route.snapshot.queryParams.returnUrl) {
              this.router.navigate([this.route.snapshot.queryParams.returnUrl]);
            } else {
              this.router.navigate(['/']);
            }
          }
          this.photosService.loadPhotos();
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

}
